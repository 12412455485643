import React, { lazy, Suspense, useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom'
import { setCookie, getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js";
import { useTheme } from "./theme-provider.js"
import PageError from '../common_component/PageError.js';

function ViewSubscription(props) {
	const [product_name, setproduct_name] = useState("");
	const [product_id, setproduct_id] = useState("");
	const [subscription_name, setsubscription_name] = useState("");
	const [add_status, setadd_status] = useState("");
	const [add_date_time, setadd_date_time] = useState("");
	const [start_date, setstart_date] = useState("");
	const [end_date, setend_date] = useState("");
	const [PurchaseDetails, setPurchaseDetails] = useState([]);
	const [PaymentDetails, setPaymentDetails] = useState([]);
	const [AddressDetails, setAddressDetails] = useState([]);
	const [state, setstate] = useState(2);
	const [Invoicedetails, setInvoicedetails] = useState([]);
	const [invoicedraft, setinvoicedraft] = useState([]);
	const [payment_gateway_id, setpayment_gateway_id] = useState("");
	const [payment_platform, setpayment_platform] = useState("");
	const [payment_type, setpayment_type] = useState("");
	const [payment_currency, setpayment_currency] = useState("");
	const [payment_amount, setpayment_amount] = useState(0);
	const [timezone_change, set_timezone_change] = useState("+00:00");
	const [Add_on_details, setAdd_on_details] = useState([]);
	var page_name = "view-subscription";

	const { user, organization_id, user_role, organization_name, paymentResponseFlag, setPaymentResponseFlag } = useAuthData();
	const { theme, dropdownStyleDark } = useTheme();

	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	useEffect(() => {
		async function getaccountdetails() {
			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-organization-timezone-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();

			//console.log(body)
			if (body.length > 0) {
				if (body[0].timezone_value != "" && body[0].timezone_value != null) {
					set_timezone_change(body[0].timezone_value)
				}
			}
		}
		user !== null && user.email && getaccountdetails();
	}, [user])

	useEffect(() => {

		async function getpurchasedetails() {

			var object = {};
			object.email = user.email;
			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-purchase-details`, { method: 'POST', body: JSON.stringify({ "id": props.match.params.id, "email": user.email, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();
			console.log(body);
			if (body.data.length > 0) {
				setproduct_id(body.data[0].product_id)
				var b = JSON.stringify(body.data[0].billing_details);
				var billing_details = b.replace(/\\/g, '');
				var billing_details1 = billing_details.replace(/"""/g, '');
				if (billing_details1.startsWith('"')) {
					billing_details1 = billing_details1.slice(1, -1);
				}
				console.log(body.data[0])
				let arr = [];
				for (let i = 0; i < body.data[0].addon_details.length; i++) {
					let add_on = JSON.parse(body.data[0].addon_details[i].billing_details).Selected_Add_on_list;

					for (let j = 0; j < add_on.length; j++) {
						add_on[j].add_date_time = (body.data[0].addon_details[i].add_date_time);
						arr.push(add_on[j])
					}
				}
				setAdd_on_details(arr)
				setPurchaseDetails(JSON.parse(billing_details1));
				setPaymentDetails(JSON.parse(body.data[0].payment_response));
				setAddressDetails(JSON.parse(body.data[0].billing_address));
				setproduct_name(body.data[0].product_name)
				setsubscription_name(body.data[0].subscription_name)
				setadd_status(body.data[0].add_status);
				setadd_date_time(body.data[0].add_date_time);
				setpayment_gateway_id(body.data[0].payment_gateway_subscription_id);
				setpayment_platform(body.data[0].payment_platform);
				setpayment_type(body.data[0].payment_type);
				setpayment_amount(body.data[0].payment_amount);
				setpayment_currency(body.data[0].payment_currency);
				setstart_date(moment(body.data[0].starting_date).format("DD MMMM YYYY"))
				setend_date(moment(body.data[0].ending_date).format("DD MMMM YYYY"))
				setstate(1);

			}
			else {
				setstate(0)
			}
		}
		(user !== null && user.email) && getpurchasedetails();
	}, [user])

	useEffect(() => {
		async function get_invoice_details() {

			var object = {};
			object.email = user.email;
			if (payment_platform == "razorpay") {
				const response = await fetch(
					`${process.env.REACT_APP_SERVER_URL}/get-invoice-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "sub_id": payment_gateway_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
				);
				const body = await response.json();
				// console.log(body)
				body.value.invoice.map((obj, index) => {
					//END DATE
					var temp_date = body.value.invoice[index].period_end;
					body.value.invoice[index].period_end = moment.unix(body.value.invoice[index].period_end).zone(timezone_change).format("DD MMMM YYYY");

					body.value.invoice[index].period_end1 = moment.unix(temp_date).utc().format("DD MMMM YYYY") + " (UTC)";


					//START DATE
					temp_date = body.value.invoice[index].period_start;
					body.value.invoice[index].period_start = moment.unix(body.value.invoice[index].period_start).zone(timezone_change).format("DD MMMM YYYY");

					body.value.invoice[index].period_start1 = moment.unix(temp_date).utc().format("DD MMMM YYYY") + " (UTC)";

				})
				body.value.invoicedraft.map((obj, index) => {
					//END DATE
					var temp_date = body.value.invoicedraft[index].period_end;
					body.value.invoicedraft[index].period_end = moment.unix(body.value.invoicedraft[index].period_end).zone(timezone_change).format("DD MMMM YYYY");

					body.value.invoicedraft[index].period_end1 = moment.unix(temp_date).utc().format("DD MMMM YYYY") + " (UTC)";

					//START DATE
					temp_date = body.value.invoicedraft[index].period_start;
					body.value.invoicedraft[index].period_start = moment.unix(body.value.invoicedraft[index].period_start).zone(timezone_change).format("DD/MM/YYYY");


					body.value.invoicedraft[index].period_start1 = moment.unix(temp_date).utc().format("DD MMMM YYYY") + " (UTC)";

				})
				//console.log(body)
				setInvoicedetails(body.value.invoice)
				setinvoicedraft(body.value.invoicedraft)
			}
		}
		payment_gateway_id && payment_platform && get_invoice_details();
	}, [payment_gateway_id, payment_platform]);

	function cancel_subscription(e) {

		var sub_id = payment_gateway_id;
		var payment_type = payment_platform;

		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}
		var values = { "sub_id": sub_id, "payment_gateway": payment_type }

		let cancel_type = document.querySelector('input[name="cancel_radio"]:checked').value;

		if (cancel_type == "") {
			swal("Oops!", "Please select which type of subscription cancellation you want!", "error");
			return false;
		}
		else if (!isNaN(cancel_type)) {
			swal("Oops!", "Please select which type of subscription cancellation you want!", "error");
			return false;
		}

		if (cancel_type == "billing_period" && payment_type == "razorpay") {

			axios.post(`${process.env.REACT_APP_SERVER_URL}/cancel-subscription-razorpay`, values, headers).then((res) => {
				if (res.data && res.data.operation == "success") {
					swal("Great!", "Subscription will be cancelled after the billing period!", "success").then((value) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			});

		}
		else if (cancel_type == "now" && payment_type == "razorpay") {

			axios.post(`${process.env.REACT_APP_SERVER_URL}/cancel-subscription-now-razorpay`, values, headers).then((res) => {
				if (res.data && res.data.operation == "success") {
					swal("Great!", "Subscription cancelled!", "success").then((value) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			});
		}
		else if (payment_type == "Trial") {

			axios.post(`${process.env.REACT_APP_SERVER_URL}/cancel-subscription-trial`, values, headers).then((res) => {
				if (res.data && res.data.operation == "success") {
					swal("Great!", "Subscription cancelled!", "success").then((value) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			});

		}
		else {
			swal("Oops!", "Please select which type of subscription cancellation you want!", "error");
			return false;
		}
	}



	return (
		<>
			<div className="d-flex flex-column flex-column-fluid">
				<div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
					<div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
						<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">View Subscricption
							<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
							<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
						</h1>
						<span className="badge badge-success fs-5">{organization_name}</span>
					</div>
				</div>
				<div id="kt_app_content" className="app-content flex-column-fluid">
					<div id="kt_app_content_container" className="app-container container-xxl">
						{state == 1 ?
							<>
								<div className="d-flex flex-column flex-lg-row">
									<div className="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">
										<div className="card card-flush pt-3 mb-5 mb-xl-10">
											<div className="card-header">
												<div className="card-title">
													<h2 className="fw-bold">Product Details</h2>
												</div>
											</div>
											<div className="card-body pt-3">
												<div className="mb-10">
													<h5 className="mb-4">Billing Address:</h5>
													<div className="d-flex table-responsive flex-wrap py-5">
														<div className="flex-equal me-5">
															<table className="table fs-6 fw-semibold gs-0 gy-2 gx-2 m-0">
																<tbody>
																	<tr>
																		<td className="text-gray-500 min-w-175px w-175px">Bill to:</td>
																		<td className="text-gray-800 min-w-200px">
																			<span className="text-gray-800 text-hover-primary">{AddressDetails.email}</span>
																		</td>
																	</tr>
																	<tr>
																		<td className="text-gray-500">Customer Name:</td>
																		<td className="text-gray-800">
																			{AddressDetails.full_name} {AddressDetails.last_name}</td>
																	</tr>
																	<tr>
																		<td className="text-gray-500">Address:</td>
																		<td className="text-gray-800">
																			{AddressDetails.city}, {AddressDetails.state},  {AddressDetails.country},  {AddressDetails.pincode}.
																		</td>
																	</tr>
																	<tr>
																		<td className="text-gray-500">Phone:</td>
																		<td className="text-gray-800">{AddressDetails.phone}</td>
																	</tr>
																</tbody></table>
														</div>
														<div className="flex-equal">
															<table className="table fs-6 fw-semibold gs-0 gy-2 gx-2 m-0">
																<tbody><tr>
																	<td className="text-gray-500 min-w-175px w-175px">Subscribed Product:</td>
																	<td className="text-gray-800 min-w-200px">
																		<span className="text-gray-800 text-hover-primary">{product_name}</span>
																	</td>
																</tr>
																	<tr>
																		<td className="text-gray-500">Subscription Fees:</td>
																		<td className="text-gray-800">
																			{PurchaseDetails.currency == "INR" ?
																				<>₹{PurchaseDetails.total_price} / {PurchaseDetails.duration}</>
																				:
																				PurchaseDetails.currency == "USD" ?
																					<>${PurchaseDetails.total_price} / {PurchaseDetails.duration}</>
																					:
																					PurchaseDetails.currency == "EUR" ?
																						<>€{PurchaseDetails.total_price} / {PurchaseDetails.duration}</>
																						:
																						null
																			}
																		</td>
																	</tr>
																	<tr>
																		<td className="text-gray-500">Billing method:</td>
																		<td className="text-gray-800">
																			{PurchaseDetails.duration === "month" ? <>Monthly</> : <>Annually</>}
																		</td>
																	</tr>
																	<tr>
																		<td className="text-gray-500">Currency:</td>
																		<td className="text-gray-800">{PurchaseDetails.currency == "USD" ? <>USD - US Dollar</> : <>INR</>}</td>
																	</tr>
																</tbody></table>
														</div>
													</div>
												</div>

												{Add_on_details.length > 0 &&
													<div className="mb-0">
														<h5 className="mb-4">Addon Products:</h5>
														<div className="table-responsive">
															<table className="table align-middle table-row-dashed fs-6 gy-4 mb-0">
																<thead>
																	<tr className="border-bottom border-gray-200 text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
																		<th className="min-w-150px">Product</th>
																		<th className="min-w-125px">Quantity</th>
																		<th className="min-w-125px">Unit Price</th>
																		<th className="min-w-125px">Total</th>
																	</tr>
																</thead>
																<tbody className="fw-semibold text-gray-800">
																	{Add_on_details.map((obj, index) => {
																		return (
																			<tr>
																				<td>
																					<label className="w-150px">{obj.Add_on_feature}</label>
																					{/* <div className="fw-normal text-gray-600">Basic yearly bundle</div> */}
																				</td>
																				<td><span className="badge badge-light-danger">{obj.quantity}</span></td>
																				<td>{PurchaseDetails.currency == "INR" ?
																					<>₹{obj.price}{obj.unit}</>
																					:
																					PurchaseDetails.currency == "USD" ?
																						<>${obj.price}{obj.unit}</>
																						:
																						PurchaseDetails.currency == "EUR" ?
																							<>€{obj.price}{obj.unit}</>
																							:
																							null
																				}</td>
																				<td>{PurchaseDetails.currency == "INR" ?
																					<>₹{obj.price}{obj.unit}</>
																					:
																					PurchaseDetails.currency == "USD" ?
																						<>${obj.price}{obj.unit}</>
																						:
																						PurchaseDetails.currency == "EUR" ?
																							<>€{obj.price}{obj.unit}</>
																							:
																							null
																				}</td>
																			</tr>
																		)
																	})}
																</tbody>
															</table>
														</div>
													</div>}
											</div>
										</div>
										{Invoicedetails.length > 0 ?
											<div className="card card-flush pt-3 mb-5 mb-xl-10">
												<div className="card-header">
													<div className="card-title">
														<h2> Paid Invoices</h2>
													</div>
													<div className="card-toolbar">
													</div>
												</div>
												<div className="card-body pt-2">
													<div id="kt_referred_users_tab_content" className="tab-content">
														<div id="kt_customer_details_invoices_1" className="tab-pane fade show active" role="tabpanel">
															<div className="table-responsive">
																<table id="kt_customer_details_invoices_table_1" className="table align-middle table-row-dashed fs-6 fw-bolder gs-0 gy-4 p-0 m-0">
																	<thead className="border-bottom border-gray-200 fs-7 text-uppercase fw-bolder">
																		<tr className="text-start text-gray-400">
																			<th className="min-w-100px">Order ID</th>
																			<th className="min-w-100px">Amount</th>
																			<th className="min-w-100px">Status</th>
																			<th className="min-w-100px">Start Date</th>
																			<th className="min-w-100px">End Date</th>
																		</tr>
																	</thead>
																	<tbody className="fs-6 fw-bold text-gray-600">
																		{Invoicedetails.length > 0 && Invoicedetails.map((obj, index) => {
																			return (
																				<>
																					{obj.status == "paid" ?
																						<tr>
																							<td>
																								<a className="text-gray-600 text-hover-primary">{obj.id}</a>
																							</td>
																							<td className="text-success">{obj.currency == "INR" ?
																								<span className="text-success">₹{obj.amount_due}</span>
																								:
																								obj.currency == "usd" ?
																									<span className="text-success">${obj.amount_due}</span>
																									:
																									obj.currency == "eur" ?
																										<span className="text-success">€{obj.amount_due}</span>
																										:
																										null
																							}
																							</td>
																							<td>
																								<span className="badge badge-light-success">Paid</span>
																							</td>
																							<td>{obj.period_start1}</td>
																							<td>{obj.period_end1}</td>

																						</tr>
																						:
																						null
																					}
																				</>
																			);
																		})}
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</div>
											</div>
											:
											null
										}
										{invoicedraft.length > 0 ?
											<div className="card card-flush pt-3 mb-5 mb-xl-10">

												<div className="card-header">

													<div className="card-title">
														<h2> Upcoming Invoices</h2>
													</div>
													<div className="card-toolbar">
													</div>
												</div>
												<div className="card-body pt-2">
													<div id="kt_referred_users_tab_content" className="tab-content">
														<div id="kt_customer_details_invoices_1" className="tab-pane fade show active" role="tabpanel">
															<div className="table-responsive">
																<table id="kt_customer_details_invoices_table_1" className="table align-middle table-row-dashed fs-6 fw-bolder gs-0 gy-4 p-0 m-0">
																	<thead className="border-bottom border-gray-200 fs-7 text-uppercase fw-bolder">
																		<tr className="text-start text-gray-400">
																			<th className="min-w-100px">Order ID</th>
																			<th className="min-w-100px">Amount</th>
																			<th className="min-w-100px">Status</th>
																			<th className="min-w-100px">Start Date</th>
																			<th className="min-w-100px">End Date</th>
																		</tr>
																	</thead>
																	<tbody className="fs-6 fw-bold text-gray-600">
																		{invoicedraft.length > 0 && invoicedraft.map((obj, index) => {
																			return (
																				<>
																					{obj.status == "draft" ?
																						<tr>
																							<td>
																								<a className="text-gray-600 text-hover-primary">{obj.id}</a>
																							</td>
																							<td className="text-success">{obj.currency == "inr" ?
																								<span className="text-success">₹{obj.amount_due}</span>
																								:
																								obj.currency == "usd" ?
																									<span className="text-success">${obj.amount_due}</span>
																									:
																									obj.currency == "eur" ?
																										<span className="text-success">€{obj.amount_due}</span>
																										:
																										null
																							}
																							</td>
																							<td>
																								<span className="badge badge-light-success">Draft</span>
																							</td>
																							<td>{obj.period_start1}</td>
																							<td>{obj.period_end1}</td>

																						</tr>
																						:
																						null
																					}
																				</>
																			);
																		})}
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</div>
											</div>
											:
											null
										}
									</div>
									<div className="flex-column flex-lg-row-auto w-lg-250px w-xl-300px mb-10 order-1 order-lg-2">
										<div className="card card-flush mb-0">
											<div className="card-header">
												<div className="card-title">
													<h2>Summary</h2>
												</div>
											</div>
											<div className="card-body pt-0 fs-6">
												<div className="mb-3">
													<h5 className="mb-4">Payment Details</h5>
													<table className="table fs-6 fw-semibold gs-0 gy-2 gx-2">
														<tbody>
															<tr className="">
																<td className="text-gray-500">Type:</td>
																<td className="text-gray-800">{payment_type}</td>
															</tr>
															<tr className="">
																<td className="text-gray-500">Platform:</td>
																<td className="text-gray-800">{payment_platform}</td>
															</tr>
															<tr className="">
																<td className="text-gray-500">Payment Amount:</td>
																<td className="text-gray-800">{payment_currency == "usd" ?<></>:payment_currency == "eur" ?<></>:<>₹</>}{payment_amount}</td>
															</tr>
														</tbody></table>
												</div>
												<div className="separator separator-dashed mb-7"></div>
												<div className="mb-3">
													<h5 className="mb-4">Subscription Details</h5>
													<table className="table fs-6 fw-semibold gs-0 gy-2 gx-2">
														<tbody>
															<tr className="">
																<td className="text-gray-500">Subscription:</td>
																<td className="text-gray-800">{subscription_name}</td>
															</tr>
															<tr className="">
																<td className="text-gray-500">Started:</td>
																<td className="text-gray-800">{start_date}</td>
															</tr>
															<tr className="">
																<td className="text-gray-500">End Date:</td>
																<td className="text-gray-800">{end_date}</td>
															</tr>
															<tr className="">
																<td className="text-gray-500">Status:</td>
																<td><span className={`badge ${add_status=="Stop"?"badge-light-danger":"badge-light-success"}`}>{add_status}</span></td>
															</tr>
															<tr className="">
																<td className="text-gray-500">Duration:</td>
																<td className="text-gray-800">{moment(end_date).diff(moment(start_date), 'days')} Days &nbsp; <span className="fs-7 text-danger d-flex align-items-center">
																	<span className="bullet bullet-dot bg-danger me-2"></span>Expiry in {moment(end_date).diff(moment(), 'days') + 1} days</span></td>
															</tr>

														</tbody></table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</>
							:
							<>
								{state == 2 ?
									<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
									:
									<>
										<PageError />
									</>
								}
							</>
						}
					</div>
				</div>
			</div>

			<div className="modal fade" id="kt_modal_1" tabindex="-1" aria-hidden="true">
				<div className="modal-dialog mw-650px">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">Cancel Subscription</h5>
							<div className="modal-header pb-0 border-0 justify-content-end">
								<div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
									<span className="svg-icon svg-icon-1">
										<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
											<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
												<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
												<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
											</g>
										</svg>
									</span>
								</div>
							</div>
						</div>
						<div className="modal-body">
							<div className="form-group row">
								<div className="col-lg-12">
									Current subscription status is : {<span className="fs-5 fw-bolder text-success"> Active </span>}<br /><br /><br />
									<div className="mb-0 fv-row">
										<label className="d-flex align-items-center form-label mb-5">Select Cancel Mode
											<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title=""></i></label>
										<div className="mb-0">
											<label className="d-flex flex-stack mb-5 cursor-pointer">
												<span className="d-flex align-items-center me-2" for="once">
													<span className="d-flex flex-column">
														<span className="fw-bolder text-gray-800 text-hover-primary fs-5">Cancel now</span>
													</span>
												</span>
												<span className="form-check form-check-custom form-check-solid">
													<input className="form-check-input" id="cancel_radio" type="radio" name="cancel_radio" value="now" checked="checked" />
												</span>
											</label>
											<label className="d-flex flex-stack mb-5 cursor-pointer">
												<span className="d-flex align-items-center me-2" for="repeat">
													<span className="d-flex flex-column">
														<span className="fw-bolder text-gray-800 text-hover-primary fs-5">Cancel After Billing Period</span>
													</span>
												</span>
												<span className="form-check form-check-custom form-check-solid" >
													<input className="form-check-input" id="cancel_radio" type="radio" name="cancel_radio" value="billing_period" />
												</span>

											</label>
										</div>
									</div>
									<br /><br />
									<h3>Are you sure about cancelling subscription?</h3>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
							<form >
								<button type="button" className="btn btn-danger" onClick={(e) => { cancel_subscription(e); }} >Yes</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default ViewSubscription;