import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'

import { setCookie, getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js";
import { useTheme } from "./theme-provider.js"
import moment from "moment";
import PageError from '../common_component/PageError.js';

function SubscriptionList(props) {

	const [subscription_features, setSubscriptionFeatures] = useState([]);
	const [product_details, setProductDetails] = useState([]);
	const [product, setProduct] = useState("");
	const [state, setstate] = useState(2);
	const [Organization_admin, setOrganization_admin] = useState("");

	const [check, setcheck] = useState(0);
	const [duration, setduration] = useState("mon");

	var page_name = "subscription-list";

	const { user, organization_id, user_role, organization_name,organization_currency } = useAuthData();
	const { theme, dropdownStyleDark } = useTheme();

	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	async function getOrganizationAdmin() {

		var object = {};
		object.email = user.email;


		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-Organization-Admin`, { method: 'POST', body: JSON.stringify({ "email": user.email, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		//console.log(body);
		if (body.operation == "success") {
			setOrganization_admin(body.data[0].user_id);
		}
	}

	async function getsubscriptionfeatureslist() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-subscription-feature-list`, {
			method: 'GET', headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
		}
		);
		const body = await response.json();
		if (body.operation == "success") {
			setSubscriptionFeatures(body.data)
		}
	}

	async function getsubscriptionlist() {

		var object = {};
		//console.log("id", props.match.params.id);
		if (props.match.params.id !== undefined) {
			object.product_id = props.match.params.id;
			object.flag = "specific";
		}
		else {
			object.flag = "all";
		}

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-all-subscription-list`, { method: 'POST', body: JSON.stringify(object), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		if (body.operation == "success") {
			let currency1 = organization_currency.currency_code;
			body.data.forEach((obj1, index1) => {
				obj1.subscription_list.forEach((obj, index) => {
					obj.select_duration = "Monthly";
					let json = JSON.parse(obj.subscription_charges);
					let json2 = JSON.parse(obj.subscription_features);
					obj.subscription_charges = json;
					json.map((obj2, index2) => {

						if (currency1 == obj2.currency || (obj2.default == "Yes" && currency1 == "default")) {
							obj.currency = obj2.currency;

							if (currency1 == "default") {
								currency1 = obj2.currency
							}

							obj2.charges.map((obj3, index3) => {
								if (obj3.duration_in_month == "1") {
									obj.monthly_charge = Number(obj3.charges - obj3.discount_amount);
									obj.monthly_charge_without_discount = obj3.charges;
									obj.monthly_charge_unit = obj3.duration_in_letter;
									obj.monthly_discount = obj3.discount_amount;
									obj.price = Number(obj3.charges - obj3.discount_amount);
								}
								if (obj3.duration_in_month == "12") {
									obj.yearly_charge = Number(obj3.charges - obj3.discount_amount);
									obj.yearly_charge_without_discount = obj3.charges;
									obj.yearly_charge_unit = obj3.duration_in_letter;
									obj.yearly_discount = obj3.discount_amount;
									obj.price = Number(obj3.charges - obj3.discount_amount);
								}
								if (obj3.duration_in_month == "3") {
									obj.quarterly_charge = Number(obj3.charges - obj3.discount_amount);
									obj.quarterly_charge_without_discount = obj3.charges;
									obj.quarterly_charge_unit = obj3.duration_in_letter;
									obj.quarterly_discount = obj3.discount_amount;
									obj.price = Number(obj3.charges - obj3.discount_amount);
								}
								if (obj3.duration_in_month == "6") {
									obj.half_yearly_charge = Number(obj3.charges - obj3.discount_amount);
									obj.half_yearly_charge_without_discount = obj3.charges;
									obj.half_yearly_charge_unit = obj3.duration_in_letter;
									obj.half_yearly_discount = obj3.discount_amount;
									obj.price = Number(obj3.charges - obj3.discount_amount);
								}
							})
						}
					})
					//console.log(subscription_features);

					json2.map((obj4, index4) => {
						if (obj4.duration == "mon") {
							obj4.select_duration = "Monthly";
						}
						if (obj4.duration == "year") {
							obj4.select_duration = "Annually";
						}
						if (obj4.duration == "3 mon") {
							obj4.select_duration = "Quarterly";
						}
						if (obj4.duration == "6 mon") {
							obj4.select_duration = "Half_yearly";
						}
					})
					//console.log(json2);
					obj.subscription_features = json2;
				})
				obj1.subscription_list.sort((a, b) => {
					if (moment(a.add_date_time).isAfter(b.add_date_time)) {
						return 1;
					}
					else {
						return -1;
					}
				})
			})
			console.log(body.data)
			setProductDetails(body.data);
			setProduct(body.data[0].unique_id);
			setstate(1)

		}
	}

	useEffect(() => {
		if (user !== null && user.email) {
			let p1 = getOrganizationAdmin()
			let p2 = getsubscriptionfeatureslist();
			Promise.all([p1, p2])
				.then(async() => {
					//await getsubscriptionlist()
					//setstate(1)
					console.log("all apis done")
				})
				.catch((err) => {
					setstate(0)
					console.log(err)
				})
		}
	}, [user])

	useEffect(() => {
		(user !== null && user.email && organization_currency!==null) && getsubscriptionlist()
	}, [organization_currency, user])

	const Purcahse_validity_check = () => {
		swal("Permission Denied!", "You don't have any permission to purchase. Please reach out to your Admin.", "warning").then((value) => {

		});
	}

	/*useEffect(() => {


	},[currency,subscription_details])*/

	return (
		<>
			{
				(check === 1 ? <>
					<Redirect to={{
						pathname: "/subscription-list/" + product,
						state: { duration: duration }
					}}
					/>
				</> : null)
			}

			<div className="d-flex flex-column flex-column-fluid">
				<div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
					<div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
						<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Subscription List
							<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
							<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
						</h1>
						<span class="badge badge-success fs-5">{organization_name}</span>
					</div>
				</div>
				<div id="kt_app_content" className="app-content flex-column-fluid">
					<div id="kt_app_content_container" className="app-container container-xxl">
						{state == 1 ?
							<>
								<div class="card">
									<div class="card-body p-lg-17">
										<div class="d-flex flex-column">
											<div class="mb-13 text-center">
												<h1 class="fs-2hx fw-bold mb-5">Choose Your Plan</h1>
												{user.email != Organization_admin && <span class="badge py-3 px-4 fs-7 badge-light-danger">You don't have any permission to purchase. Please reach out to your Admin. </span>}
											</div>
											<div className="card-rounded bg-light d-flex flex-stack flex-wrap p-5 col-12">
												<ul className="nav flex-wrap border-transparent fw-bolder col-12">
													{product_details.map((obj, index) => {
														if (index == 0) {
															return (
																<li className="nav-item my-1 col-4" role="presentation">
																	<a onClick={(event) => { setProduct(obj.unique_id) }} className="btn btn-color-gray-600 btn-active-white btn-active-color-primary fw-boldest fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase active" data-bs-toggle="tab" role="tab" href={`#kt_activity_product${index + 1}`} aria-selected="true" >{obj.product_name}</a>
																</li>
															);
														}
														else {
															return (
																<li className="nav-item my-1 col-4" role="presentation">
																	<a onClick={(event) => { setProduct(obj.unique_id) }} className="btn btn-color-gray-600 btn-active-white btn-active-color-primary fw-boldest fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase" data-bs-toggle="tab" role="tab" href={`#kt_activity_product${index + 1}`} aria-selected="true">{obj.product_name}</a>
																</li>
															);
														}
													})}

												</ul>
											</div><br />

											<div class="row g-10">
												{product_details.length > 0 && product_details.filter(option => option.unique_id == product)[0].subscription_list.map((obj1, index1) => {
													return (
														<div class="col-xl-4">
															<div class="d-flex h-100 align-items-center">
																<div class="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10">
																	<div class="mb-7 text-center">
																		<h1 class="text-dark mb-5 fw-bolder">{obj1.subscription_name}</h1>
																		<div class="text-gray-400 fw-semibold mb-5">{obj1.short_description}</div>
																		<div class="nav bg-light rounded-pill px-3 py-2 ms-9 mb-15 w-225px" data-kt-buttons="true" data-kt-initialized="1">

																			{obj1.monthly_charge != undefined &&
																				<button class={`nav-link btn btn-active btn-active-dark fw-bold btn-color-gray-600 py-3 px-5 m-1 rounded-pill ${obj1.select_duration == "Monthly" && "active"}`} onClick={(e) => {
																					const values1 = [...product_details];
																					values1.filter(option => option.unique_id == product)[0].subscription_list[index1].select_duration = "Monthly";
																					setProductDetails(values1);
																					setduration("mon");
																				}}>Monthly</button>
																			}
																			{obj1.quarterly_charge != undefined &&
																				<button class={`nav-link btn btn-active btn-active-dark fw-bold btn-color-gray-600 py-3 px-5 m-1 rounded-pill ${obj1.select_duration == "Quarterly" && "active"}`} onClick={(e) => {
																					const values1 = [...product_details];
																					values1.filter(option => option.unique_id == product)[0].subscription_list[index1].select_duration = "Quarterly";
																					setProductDetails(values1);
																					setduration("3 mon");
																				}}>Quarterly</button>
																			}
																			{obj1.half_yearly_charge != undefined &&
																				<button class={`nav-link btn btn-active btn-active-dark fw-bold btn-color-gray-600 py-3 px-5 m-1 rounded-pill ${obj1.select_duration == "Half_yearly" && "active"}`} onClick={(e) => {
																					const values1 = [...product_details];
																					values1.filter(option => option.unique_id == product)[0].subscription_list[index1].select_duration = "Half_yearly";
																					setProductDetails(values1);
																					setduration("6 mon");
																				}}>Half yearly</button>
																			}
																			{obj1.yearly_charge != undefined &&
																				<button class={`nav-link btn btn-active btn-active-dark fw-bold btn-color-gray-600 py-3 px-5 m-1 rounded-pill ${obj1.select_duration == "Annually" && "active"}`} onClick={(e) => {
																					const values1 = [...product_details];
																					values1.filter(option => option.unique_id == product)[0].subscription_list[index1].select_duration = "Annually";
																					setProductDetails(values1);
																					setduration("year");
																				}}>Annually</button>
																			}
																		</div>

																		<div class="text-center">
																			<span class="fs-2x mb-2 text-primary">{(organization_currency.currency_code!==null && organization_currency.currency_code === "USD") ? "$" : <>Rs.</>}</span>
																			<span class="fs-3x fw-bold text-primary">{obj1.select_duration == "Monthly" ? obj1.monthly_charge : obj1.select_duration == "Half_yearly" ? obj1.half_yearly_charge : obj1.select_duration == "Quarterly" ? obj1.quarterly_charge : obj1.select_duration == "Annually" && obj1.yearly_charge}</span>
																			{(obj1.select_duration == "Monthly" && Number(obj1.monthly_discount) > 0) ? <del><span class="fs-2 mb-2 text-danger">{obj1.monthly_charge_without_discount}</span></del> : (obj1.select_duration == "Half_yearly" && Number(obj1.half_yearly_discount) > 0) ? <del><span class="fs-2 mb-2 text-danger">{obj1.half_yearly_charge_without_discount}</span></del> : (obj1.select_duration == "Quarterly" && Number(obj1.quarterly_discount) > 0) ? <del><span class="fs-2 mb-2 text-danger">{obj1.quarterly_charge_without_discount}</span></del> : (obj1.select_duration == "Annually" && Number(obj1.yearly_discount) > 0) && <del><span class="fs-2 mb-2 text-danger">{obj1.yearly_charge_without_discount}</span></del>}
																			<span class="fs-2 fw-semibold opacity-50">/
																				<span data-kt-element="period">{obj1.select_duration == "Monthly" ? obj1.monthly_charge_unit : obj1.select_duration == "Half_yearly" ? "Half yearly" : obj1.select_duration == "Quarterly" ? "Quarterly" : obj1.select_duration == "Annually" && obj1.yearly_charge_unit}</span></span>
																		</div>
																	</div>

																	<div class="w-100 mb-10">
																		{
																			subscription_features.map((obj2, index2) => {

																				if (obj2.value_applicability == 0 && obj2.boolean_applicability == 1) {
																					return (
																						<>
																							{
																								(obj1.subscription_features.filter(item => item.select_duration == obj1.select_duration).length > 0 && obj1.subscription_features.filter(item => item.select_duration == obj1.select_duration)[0].features.filter(item => item.feature == obj2.unique_id).length > 0) &&
																								<div class="d-flex align-items-center mb-5">
																									<span class="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">{obj2.feature_name}</span>
																									{obj1.subscription_features.find(item => item.select_duration == obj1.select_duration).features.find(item => item.feature == obj2.unique_id).value == "false" ?
																										<i class="fa-sharp-duotone fa-solid fa-circle-xmark fs-2"></i>
																										:
																										<span class="svg-icon svg-icon-1 svg-icon-success">
																											<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																												<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
																												<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor" />
																											</svg>
																										</span>}
																								</div>
																							}
																						</>
																					);
																				}
																				else if (obj2.value_applicability == 1 && obj2.boolean_applicability == 1) {
																					return (
																						<>
																							{
																								(obj1.subscription_features.filter(item => item.select_duration == obj1.select_duration).length > 0 && obj1.subscription_features.filter(item => item.select_duration == obj1.select_duration)[0].features.filter(item => item.feature == obj2.unique_id).length > 0) &&
																								<div class="d-flex align-items-center mb-5">
																									<span class="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">{obj2.feature_name}</span>

																									<span style={{ "text-align": "right" }} className="fw-bold fs-5 text-gray-700 flex-grow-1">
																										{obj1.subscription_features.filter(item => item.select_duration == obj1.select_duration)[0].features.filter(item => item.feature == obj2.unique_id)[0].value} {obj1.subscription_features.filter(item => item.select_duration == obj1.select_duration)[0].features.filter(item => item.feature == obj2.unique_id)[0].unit}
																									</span>
																								</div>
																							}
																						</>
																					);
																				}
																			})
																		}
																	</div>
																	{/*<div class="d-flex align-items-center">
													<span class="fw-semibold fs-6 text-gray-400 flex-grow-1">Unlimited Cloud Space</span>
													
													<span class="svg-icon svg-icon-1">
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
															<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
															<rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="currentColor" />
															<rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="currentColor" />
														</svg>
													</span>
												</div>*/}
																	{user.email == Organization_admin ?
																		<Link to={`/subscription-details/${obj1.unique_id}`} onClick={(e) => { setCookie('dur', duration, 1, false); }} class="btn btn-sm btn-primary">Select</Link>
																		:
																		<Link onClick={(e) => { Purcahse_validity_check(); }} class="btn btn-sm btn-primary">Select</Link>
																	}
																</div>
															</div>
														</div>
													);
												})}
											</div>
										</div>
									</div>
								</div>

								{/*<div className="card modal-body pt-0 pb-15 px-5 px-xl-5 ">
					<br />
					<div className="mb-13 text-center">
						<h1 className="mb-3">Products</h1>
						<div className="text-gray-400 fw-bold fs-5">Check our products</div>
					</div>
					<div className="d-flex flex-column" id="kt_modal_upgrade_plan">
						<div className="card mt-5 mt-xxl-8">
							<div className="card-rounded bg-light d-flex flex-stack flex-wrap p-5 col-12">
								<ul className="nav flex-wrap border-transparent fw-bolder col-12">
									{product_details.map((obj,index) => {
										if(index == 0)
										{
											return(
											<li className="nav-item my-1 col-2" role="presentation">
												<a onClick={(event) => {setProduct(obj.unique_id)}} className="btn btn-color-gray-600 btn-active-white btn-active-color-primary fw-boldest fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase active" data-bs-toggle="tab" role="tab" href={`#kt_activity_product${index+1}`} aria-selected="true" >{obj.product_name}</a>
											</li>
											);
										}
										else{
											return(
											<li className="nav-item my-1 col-2" role="presentation">
												<a onClick={(event) => {setProduct(obj.unique_id)}} className="btn btn-color-gray-600 btn-active-white btn-active-color-primary fw-boldest fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase" data-bs-toggle="tab" role="tab" href={`#kt_activity_product${index+1}`} aria-selected="true">{obj.product_name}</a>
											</li>
											);
										}
									})}
									
								</ul>
							</div>
							
						</div>
					</div>
					<div className="d-flex flex-center flex-row-fluid pt-12">
						{write ?
						<button type="submit" className="btn btn-primary rounded" onClick={(e) => {purchase_plan();}} >View All Plans</button>
						:
						<button type="submit" className="btn btn-primary rounded" onClick={(e) => {purchase_plan(); }} disabled>View All Plans</button>
						}
					</div>
					<br />
					</div>*/}
							</>
							:
							<>
								{state == 2 ?
									<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
									:
									<>
										<div className="flex-lg-row-fluid me-lg-15">
											<div className="card card-flush pt-3 mb-5 mb-lg-10">
												<div className="card-body pt-0">
													<div className="mb-7 d-none">
														<a className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_customer_search">Select Customer</a>
														<span className="fw-bolder text-gray-500 mx-2">or</span>
														<a className="btn btn-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_users_search">Add New Customer</a>
													</div>
													<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
														<div className="d-flex flex-stack flex-grow-1">
															<div className="fw-bold">
																<h4 className="text-gray-800 fw-bolder">Subscriptions are not Available for the Product.</h4>
																<div className="fs-6 text-gray-600">
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</>
								}
							</>
						}
					</div>
				</div>
			</div>
		</>
	);
}
export default SubscriptionList;