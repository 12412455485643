import React, { useState, useEffect } from 'react';
import { useTheme } from "./theme-provider.js"
import { useAuthData } from "./auth-provider.js"
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { setCookie, getCookie } from '../common_component/cookie.js';

function AsideNavbar(props) {
	const [selected, setSelected] = useState("");
	const [isCollapsed, setIsCollapsed] = useState(false)
	const [organization_name, setOrganization_name] = useState("");
	const { theme } = useTheme();
	const history = useHistory()
	const pageList = [{link:"subscription-list",display_text:"Subscription List"},{link:"subscription-history",display_text:"Subscription History"},{link:"payment-wallet",display_text:"Payment Wallet"}]

	const optionMap = {
		"/subscription-list": ["/subscription-details"],
		"/subscription-history": ["/view-subscription"],
		"none": ["/", "/dashboard", "/login"]
	}

	// useEffect(() => {

	// 	async function getorganizationname(values) {
	// 		let object = {};
	// 		var headers = {
	// 			headers: {
	// 				"Content-Type": "application/json",
	// 				"token": getCookie("access_token"),
	// 				"Accept": "*/*"
	// 			}
	// 		}
	// 		object.organization_id = getCookie("organization_id");
	// 		axios.post(`${process.env.REACT_APP_SERVER_URL}/get-organization-name`, object, headers).then((res) => {
	// 			if (res.data.operation == "success") {
	// 				setOrganization_name(res.data.data);
	// 			}
	// 		})
	// 	}
	// 	getorganizationname()
	// }, [])

	useEffect(() => {
		let id = setInterval(() => {
			let currentUrl = window.location.href
			let t = currentUrl.split("/")

			t = (/\d/.test(t[t.length - 1])) ? t[t.length - 2] : t[t.length - 1]

			let targetOption = undefined;
			Object.keys(optionMap).forEach((elem) => {
				if (optionMap[elem].includes("/" + t)) {
					targetOption = elem
				}
			})

			if (targetOption == undefined) {
				//console.log(t);
				setSelected(t)
			}
			else {
				if (targetOption != "none") {
					//console.log(targetOption.replace("/",""));
					setSelected(targetOption.replace("/", ""))
				}
			}

		}, 500)
		return () => {
			clearInterval(id)
		}
	}, [])

	const customIcon = (menuName) => {
		<i className="fa-solid fa-shop fs-3 p-0"></i>
	}

	//Function for custom link as per item name in the json
	const customLink = (option) => {
		return `/${option}`
	}

	return (
		<>
			<div className={`h-aside-navbar ${isCollapsed ? "h-aside-navbar-collapse" : ""}`} style={{ transform: props.asideNavbarExpandMobile ? "translate(0px, 0px)" : undefined, backgroundColor: "#1e1e2d" }} ref={props.asideNavbarRef} tabIndex={-1} onBlur={() => { props.setAsideNavbarExpandMobile(false); }}>
				<div className="app-sidebar-logo px-6">
					<Link to="/">
						<img alt="Logo" src={theme === "dark" ? process.env.REACT_APP_DARK_LOGO : process.env.REACT_APP_LOGO} className={`${isCollapsed ? "h-20px" : "h-50px"}`} style={{ transition: "all 1s ease-in-out" }} />
					</Link>

					<div className={`app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate ${isCollapsed ? "active" : ""}`} onClick={() => { setIsCollapsed(!isCollapsed) }}>

						<span className="svg-icon svg-icon-2 rotate-180">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path opacity="0.5" d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z" fill="currentColor" />
								<path d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z" fill="currentColor" />
							</svg>
						</span>
					</div>
				</div>


				<div className="app-sidebar-menu flex-column-fluid" style={{ height: "70%", overflowY: "auto" }}>
					<div className="app-sidebar-wrapper hover-scroll-overlay-y">
						<div className="menu menu-column menu-rounded menu-sub-indention overflow-hidden px-3">
							{/* <div class="menu-item pt-5">
								<div class="menu-content">
									<span class="badge badge-success fs-5">{organization_name}</span>
								</div>
							</div> */}
							{pageList.map((obj, index) => {
							return (
							<Link to={customLink(obj.link)}>
							<div className="menu-item">
								<span className={`menu-link text-white-50 text-hover-white bg-active-light text-active-dark ${(selected === obj.link) ? "active" : ""}`} onClick={() => { setSelected(obj.link) }}>
									<span className="menu-icon">{customIcon(obj.link)}</span>
									<span className='menu-title fs-4'>{obj.display_text}</span>
								</span>
							</div>
							</Link>
							)})}
						</div>
					</div>
				</div>
				<div className="app-sidebar-footer flex-column-auto p-3">
				<button className="btn btn-light-primary w-100 text-nowrap"
						onClick={() => {
							setCookie("access_token", "", -1, true);
							setCookie("organization_id", "", -1, true);
							if (getCookie("access_token") == "" && getCookie("organization_id") == "") {
								history.push("/login")
							}
						}}
					>
						<span className="btn-icon"><i className="fa-solid fa-power-off fs-5 p-0"></i></span>
						<span className="btn-label fs-5 ms-3">Sign Out</span>
					</button>
			</div>
			</div>
		</>
	);
}
export default AsideNavbar;