import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { getCookie, setCookie } from '../common_component/cookie.js';
import { useHistory } from 'react-router-dom'
import Timer_modal from '../common_component/timer_modal.js';
import Organization_modal from '../common_component/organization_modal.js';

const initialState = {
    isAuthenticated: false,
    user: null,
    organizationId: "",
    organizationName: "",
    organizationCurrency:null,
    organizationRole: "",
    organizationList: [],
    paymentResponseFlag:"",
    setPaymentResponseFlag: ()=>{},
}

const AuthContext = createContext(initialState)

export function AuthProvider({ children }) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [organizationId, setOrganizationId] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [organizationCurrency, setOrganizationCurrency] = useState(null);
    const [organizationRole, setOrganizationRole] = useState("");
    const [organizationList, setOrganizationList] = useState([]);
    const [paymentResponseFlag, setPaymentResponseFlag] = useState("");
    const [state_org, setstate_org] = useState(2);

    const timer_interval_ref = useRef();
    const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const timer = useRef(0)
    const [clock_timer, setClockTimer] = useState(0);

    const [OrganizationModalShow, setOrganizationModalShow] = useState(false);

    const history = useHistory()

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            timer.current = timer.current - 1
            if (timer.current > 0 && timer.current < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                if (!clockTimerModalShow) {
                    setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                    setClockTimerModalShow(true)
                }
            }
        }, 1000);
        return () => { clearInterval(timer_interval_ref) };
    }, []);

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if (getCookie("organization_id") != "") {
                setOrganizationId(getCookie("organization_id"));
            }
            else {
                var object = {};
                object.page_name = "dashboard";
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
                    if(res.data.operation === "success"){
                        if (res.data.length == 1) {
                            setCookie('organization_id', res.data[0].organization_id, 1, true);
                            setOrganizationId(res.data[0].organization_id);
                        }
                        else {
                            setOrganizationList(res.data);
                            setOrganizationModalShow(true); 
                        }
                    }
                    else {
                        history.push("/login");
                    }
                })
            }
        }
        else {
            history.push("/login");
        }
    }, [])

    useEffect(() => {
        const checkAuth = async () => {
            if (getCookie("access_token") !== "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {
                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object);
                        object.organization_id = organizationId;
                        axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {
                            if (res.data.operation == "success") {
                                setOrganizationRole(res.data.user_role);
                                var token = res.data.audience_license;
                                setOrganizationName(res.data.organization_details[0].organization_name);
                                setOrganizationCurrency(res.data.organization_details[0]);
                                if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                                    setCookie('access_token', res.data.audience_license, 1, true)
                                    timer.current = process.env.REACT_APP_JWT_EXPIRY;
                                }
                                else {
                                    timer.current = res.data.audience_count;
                                }
                            }
                            else {
                                history.push("/login");
                            }
                        })
                    }
                    else {
                        history.push("/login");
                    }
                })
            }
            else {
                history.push("/login");
            }
        };

        organizationId !== "" && checkAuth(); // Call the checkAuth function on component mount

    }, [organizationId]);

    const value = {
        isAuthenticated,
        user,
        organizationId,
        organizationName,
        organizationCurrency,
        organizationRole,
        organizationList,
        paymentResponseFlag,
        setPaymentResponseFlag

    };

    return (
        <AuthContext.Provider value={value}>
            {children}
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organizationId} timer_ref={timer} />
            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organizationName} organization_currency={organizationCurrency} organization_list={organizationList} organization_id={organizationId} state_org={state_org} />
        </AuthContext.Provider>
    )
};

export const useAuthData = () => {
    const context = useContext(AuthContext)
    const history = useHistory()
    if (context === undefined)
        throw new Error("useAuthData must be used within a AuthProvider")

    return { ...context,organization_id: context.organizationId, user_role: context.organizationRole,organization_name:context.organizationName,organization_currency:context.organizationCurrency }

}
