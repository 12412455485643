import { createContext, useContext, useEffect, useState } from "react"

let dropdownStyleDark = {
    option: (provided, state) => ({
        ...provided,
        color: '#dddddf',
        backgroundColor: state.isSelected ? provided.backgroundColor : state.isFocused ? '#5f5f8d' : provided.backgroundColor,
    }),
    control: (provided, state) => ({
        ...provided,
        backgroundColor: "#2b2b40",
        borderColor: "#4c4c71"
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: "#dddddf",
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: "#dddddf",
    }),
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: "#2b2b40",
    })
}

const initialState = {
    theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light",
    themeSelection: "system",
    setTheme: () => null,
    dropdownStyleDark
}

const ThemeProviderContext = createContext(initialState)

export function ThemeProvider({ children, defaultTheme = "system", storageKey = "ui-theme", ...props }) {
    const [themeSelection, setThemeSelection] = useState(() => (localStorage.getItem(storageKey)) || defaultTheme)

    useEffect(() => {
        const root = window.document.documentElement
        root.removeAttribute("data-theme")

        if (themeSelection === "system") {
            const systemTheme = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light"
            root.setAttribute("data-theme", systemTheme)
            return
        }

        root.setAttribute("data-theme", themeSelection)
    }, [themeSelection])

    const value = {
        theme: themeSelection === "system" ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : themeSelection,
        themeSelection,
        setThemeSelection: (theme) => {
            localStorage.setItem(storageKey, theme)
            setThemeSelection(theme)
        },
        dropdownStyleDark
    }

    return (
        <ThemeProviderContext.Provider {...props} value={value}>
            {children}
        </ThemeProviderContext.Provider>
    )
}

export const useTheme = () => {
    const context = useContext(ThemeProviderContext)

    if (context === undefined)
        throw new Error("useTheme must be used within a ThemeProvider")

    return context
}
