import React, { lazy, Suspense, useState, useEffect, useRef } from 'react';
import { useAuthData } from "./auth-provider.js";
import { useTheme } from "./theme-provider.js"
import PageError from '../common_component/PageError.js';

function Dashboard() {
	let [state, setstate] = useState(1);

	var page_name = "dashboard";

	const { user, organization_id, user_role, organization_name } = useAuthData();
    const { theme, dropdownStyleDark } = useTheme();

	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	return (
		<>
			<div className="d-flex flex-column flex-column-fluid">
				<div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
					<div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
						<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Dashboard
							<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
							<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
						</h1>
						<span class="badge badge-success fs-5">{organization_name}</span>
					</div>
				</div>
				<div id="kt_app_content" className="app-content flex-column-fluid">
					<div id="kt_app_content_container" className="app-container container-xxl">
						{state == 1 ?
							<>

							</>
							:
							<>
								{state == 2 ?
									<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
									:
									<>
										<PageError/>
									</>
								}
							</>
						}
					</div>
				</div>
			</div >


			<div className="modal fade in" id="kt_activate_trial" tabindex="-1" aria-hidden="true">

				<div className="modal-dialog modal-dialog-centered mw-650px mh-950px">

					<div className="modal-content">

						<div className="modal-header" id="kt_modal_new_address_header">

							<h2>Activate Trial</h2>

							<div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

								<span className="svg-icon svg-icon-1">
									<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
										<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
											<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
											<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
										</g>
									</svg>
								</span>

							</div>

						</div>

						<div className="modal-body py-10 px-lg-17">

							<div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

								<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-3 p-6">

									<span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
										<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
											<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
											<rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
											<rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
										</svg>
									</span>


									<div className="d-flex flex-stack flex-grow-1">

										<div className="fw-bold">
											<h4 className="text-gray-800 fw-bolder">Trial Alert</h4>
											<div className="fs-6 text-gray-600">You can activate you Trial Subscription now.</div>
										</div>

									</div>

								</div>



							</div>

						</div>

						<div className="modal-footer flex-center">

							<button type="button" className="btn btn-primary" data-bs-dismiss="modal">
								<span className="indicator-label">Maybe Later</span>
							</button>

							<button type="button" onClick={(e) => { window.location.href = "/purchase-trial" }} className="btn btn-success">
								<span className="indicator-label">Activate now</span>
								<span className="indicator-progress">Please wait...
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
							</button>

						</div>

					</div>
				</div>
			</div>
		</>
	);
}
export default Dashboard;