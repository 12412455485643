import { Redirect, Switch, Route } from "react-router-dom";
import React, { useState, useEffect, useRef } from 'react';
import Dashboard from './includes/dashboard.js';
import Header from "./includes/header.js";
import Footer from "./includes/footer.js";
import SubscriptionList from './includes/subscription-list.js';
import NotFound from "./includes/NotFound.js";
import AsideNavbar from "./includes/aside-navbar.js";
import Signin from './includes/sign-in.js';
import SubscriptionHistory from "./includes/subscription-history.js";
import AddAddon from './includes/add-addon.js';
import ViewSubscription from './includes/view-subscription.js';
import PaymentResponse from './includes/payment-response.js';
import PaymentWalletResponse from './includes/payment-wallet-response.js';
import PaymentWallet from './includes/payment-wallet.js';
import SubscriptionDeatails from './includes/subscription-details.js';

import { AuthProvider, useAuthData } from "./includes/auth-provider";

export default function BasePage() {

	return (
		<>
			<Switch>
				<Route exact path={"/login"} component={Signin} />
				<Route exact from="/not-found" component={NotFound} />
				<AuthProvider>
					<Route component={DefaultContainer} />
				</AuthProvider>
			</Switch>
		</>
	);
}

const DefaultContainer = () => {
	const [asideNavbarExpandMobile, setAsideNavbarExpandMobile] = useState(false);
	const asideNavbarRef = useRef();
	const carRef = useRef();

	return (
		<>
			<div className="d-flex position-relative" style={{ maxHeight: "100vh", height: "100vh" }}>
				<AsideNavbar asideNavbarExpandMobile={asideNavbarExpandMobile} setAsideNavbarExpandMobile={setAsideNavbarExpandMobile} asideNavbarRef={asideNavbarRef} />
				<div className="flex-column flex-row-fluid">
					<Header setAsideNavbarExpandMobile={setAsideNavbarExpandMobile} asideNavbarRef={asideNavbarRef} />
					<div className="flex-column flex-row-fluid p-5" style={{ maxHeight: "80%", height: "80%", overflowY: "auto" }}>
						<Switch>
							<Route exact path={"/subscription-history"} component={SubscriptionHistory} />
							<Route exact path={"/view-subscription/:id"} render={(props) => <ViewSubscription {...props} />} />
							<Route exact path={"/add-addon/:id"} render={(props) => <AddAddon {...props} />} />
							<Route exact path={"/payment-response/:response/:id"} render={(props) => <PaymentResponse {...props} />} />
							<Route exact path={"/payment-wallet-response/:response/:id"} render={(props) => <PaymentWalletResponse {...props} />} />
							<Route exact path={"/payment-wallet"} render={(props) => <PaymentWallet {...props} />} />
							<Route exact from="/" component={Dashboard} />
							<Route exact path={"/subscription-list"} component={SubscriptionList} />
							<Route exact path={"/subscription-list/:id"} component={SubscriptionList} />
							<Route exact path={"/subscription-details/:id"} component={SubscriptionDeatails} />

							<Route from="*" ><Redirect to="/not-found" /> </Route>
						</Switch>
					</div>
					<Footer />
				</div>
			</div>
		</>
	)
}
