import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom'
import { setCookie, getCookie } from '../common_component/cookie.js';
import ReactToPrint, { useReactToPrint } from "react-to-print";

import { useAuthData } from "./auth-provider.js";
import { useTheme } from "./theme-provider.js"
import PageError from '../common_component/PageError.js';
import { useHistory } from 'react-router-dom'

export const PaymentResponse = (props) => {
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
	const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);
	const [PaymentDetails, setPaymentDetails] = useState(null);

	const printComponentRef = useRef();
	const [printData, setPrintData] = useState(undefined);
	const [paymentStatus, setPaymentStatus] = useState(false);
	const history = useHistory()

	var page_name = "payment-response";

	const { user, organization_id, user_role, organization_name,setPaymentResponseFlag } = useAuthData();
	const { theme, dropdownStyleDark } = useTheme();

	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	useEffect(() => {
		user!==null &&  user.email && updatePaymentStatus(props.match.params.response);
	}, [user])

	async function updatePaymentStatus(flag) {
		//console.log(props.match.params.response);
		var object = {};
		object.email = user.email;
		object.id = props.match.params.id;
		const api = flag == "success" ? "stripe-success" : flag == "intsant_wallet_success" ? "stripe-wallet-subscription-success" : "stripe-fail";
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/${api}`, { method: 'POST', body: JSON.stringify({ "email": user.email, "id": props.match.params.id, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		if (body.operation == "success") {
			setPaymentResponseFlag("success");
			history.push("/subscription-history");
		}
		else
		{
			setPaymentResponseFlag("failure");
			history.push("/subscription-history");
		}
	}

	const beforePrintFunction = (temp) => {

		let printBody_kot = <div id="kt_app_content_container" className="app-container container-xxl">
			<div className="card  pt-0 pb-5 px-3 px-xl-5">
				{(PaymentDetails !== null && PaymentDetails.data == "found") &&
					<>
						<div className="card-body p-lg-20" id="printDiv">

							<div className="d-flex flex-column flex-xl-row">

								<div className="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0">

									<div className="mt-n1">
										<div class="d-flex flex-stack pb-10">
											<a >
												<img alt="Logo" src="https://cdn-getlivealerts.s3.amazonaws.com/sites/images/get-live-alerts.png" class="h-40px  logo" />
											</a>
										</div>

										<div className="m-0">

											<div className="fw-bolder fs-3 text-gray-800 mb-8">Invoice</div>


											<div className="row g-5 mb-11">

												<div className="col-sm-6">

													<div className="fw-bold fs-7 text-gray-600 mb-1">Date:</div>

													<div className="fw-bolder fs-6 text-gray-800">{moment(PaymentDetails.value.starting_date).format('DD MMMM YYYY')}</div>

												</div>

												{
													props.match.params.response == "success" ?
														<div className="col-sm-6">

															<div className="fw-bold fs-7 text-gray-600 mb-1">Expiry Date:</div>
															<div className="fw-bolder fs-6 text-gray-800 d-flex align-items-center flex-wrap">
																<span className="pe-2">{moment(PaymentDetails.value.ending_date).format('DD MMMM YYYY')}</span>
																<span className="fs-7 text-danger d-flex align-items-center">
																	<span className="bullet bullet-dot bg-danger me-2"></span>Expiry in {moment(PaymentDetails.value.ending_date).diff(moment(), 'days') + 1} days</span>
															</div>

														</div>
														:
														null
												}

											</div>

											{
												props.match.params.response == "success" ?
													<div className="row g-5 mb-12">

														<div className="col-sm-6">

															<div className="fw-bold fs-7 text-gray-600 mb-1">Issued For:</div>

															<div className="fw-bolder fs-6 text-gray-800">{(PaymentDetails.value.billing_address).full_name}</div>

															<div className="fw-bold fs-7 text-gray-600">{(PaymentDetails.value.billing_address).city} {(PaymentDetails.value.billing_address).state}
																<br />{(PaymentDetails.value.billing_address).country} </div>

														</div>


														<div className="col-sm-6">

															<div className="fw-bold fs-7 text-gray-600 mb-1">Issued By:</div>


															<div className="fw-bolder fs-6 text-gray-800">CodeLab Inc.</div>


															<div className="fw-bold fs-7 text-gray-600">9858 South 53rd Ave.
																<br />Matthews, NC 28104</div>

														</div>

													</div>
													:
													null
											}
											<div className="flex-grow-1">
												<div className="table-responsive border-bottom mb-9">
													<table className="table mb-3">
														<thead>
															<tr className="border-bottom fs-6 fw-bolder text-gray-400">
																<th className="min-w-175px pb-2">Product</th>
																<th className="min-w-70px text-end pb-2">Quantity</th>
																<th className="min-w-70px text-end pb-2">Unit Price</th>
																<th className="min-w-100px text-end pb-2">Amount</th>
															</tr>
														</thead>
														<tbody>
															<tr className="fw-bolder text-gray-700 fs-5 text-end">
																<td className="d-flex align-items-center pt-6">
																	<i className="fa fa-genderless text-danger fs-2 me-2"></i>{PaymentDetails.value.product_name}-{PaymentDetails.value.subscription_name}
																</td>
																<td className="pt-6">{JSON.parse(PaymentDetails.value.billing_details).quantity}</td>
																<td className="pt-6">{PaymentDetails.value.currency == "EUR" ? "€" : (PaymentDetails.value.currency == "USD") ? "$" : (PaymentDetails.value.currency == "INR") ? "₹" : null} {JSON.parse(PaymentDetails.value.billing_details).price}</td>
																<td className="fw-bold text-gray-700 fs-5 text-end">{PaymentDetails.value.currency == "EUR" ? "€" : (PaymentDetails.value.currency == "USD") ? "$" : (PaymentDetails.value.currency == "INR") ? "₹" : null} {JSON.parse(PaymentDetails.value.billing_details).total_price}</td>
															</tr>
															{JSON.parse(PaymentDetails.value.billing_details).Selected_Add_on_list.map((obj, index) => {
																return (
																	<tr className="fw-bolder text-gray-700 fs-5 text-end">
																		<td className="d-flex align-items-center pt-6">
																			<i className="fa fa-genderless text-danger fs-2 me-2"></i>{obj.Add_on_feature}-AddOn
																		</td>
																		<td className="pt-6">{obj.quantity}</td>
																		<td className="pt-6">{PaymentDetails.value.currency == "EUR" ? "€" : (PaymentDetails.value.currency == "USD") ? "$" : (PaymentDetails.value.currency == "INR") ? "₹" : null} {obj.price}</td>
																		<td className="fw-bold text-gray-700 fs-5 text-end">{PaymentDetails.value.currency == "EUR" ? "€" : (PaymentDetails.value.currency == "USD") ? "$" : (PaymentDetails.value.currency == "INR") ? "₹" : null}{obj.price * obj.quantity}</td>
																	</tr>
																);
															}
															)}
														</tbody>
													</table>
												</div>
												<div className="d-flex justify-content-end">
													<div className="mw-300px">
														{PaymentDetails.value.amount_subtotal != undefined
															&&
															<div className="d-flex flex-stack mb-3">
																<div className="fw-bold pe-10 text-gray-600 fs-7">Subtotal:</div>
																<div className="text-end fw-bolder fs-6 text-gray-800">{PaymentDetails.value.currency == "EUR" ? "€" : (PaymentDetails.value.currency == "USD") ? "$" : (PaymentDetails.value.currency == "INR") ? "₹" : null} {PaymentDetails.value.amount_subtotal}</div>
															</div>
														}
														{
															PaymentDetails.value.discount != undefined &&
															<div className="d-flex flex-stack mb-3">
																<div className="fw-bold pe-10 text-gray-600 fs-7">Discount:</div>
																<div className="text-end fw-bolder fs-6 text-gray-800">-{PaymentDetails.value.currency == "EUR" ? "€" : (PaymentDetails.value.currency == "USD") ? "$" : (PaymentDetails.value.currency == "INR") ? "₹" : null} {PaymentDetails.value.discount}</div>
															</div>
														}
														<div className="d-flex flex-stack">
															<div className="fw-bold pe-10 text-gray-600 fs-7">Total</div>
															<div className="text-end fw-bolder fs-6 text-gray-800">{PaymentDetails.value.currency == "EUR" ? "€" : (PaymentDetails.value.currency == "USD") ? "$" : (PaymentDetails.value.currency == "INR") ? "₹" : null} {PaymentDetails.value.payment_amount}</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="m-0">
									<div className="d-print-none border border-dashed border-gray-300 card-rounded h-lg-100 min-w-md-350px p-9 bg-lighten">
										<div className="mb-8">
											{
												(PaymentDetails.value.payment_status == "Verified") ?
													<span className="badge badge-light-success rounded">Payment {PaymentDetails.value.payment_status}</span>
													:
													<span className="badge badge-light-warning rounded">Payment Status Pending</span>
											}
										</div>
										<h6 className="mb-8 fw-boldest text-gray-600 text-hover-primary">PAYMENT DETAILS</h6>
										<div className="mb-6">
											<div className="fw-bold text-gray-600 fs-7">Email:</div>
											<div className="fw-bolder text-gray-800 fs-6">{user.email}</div>
										</div>
										<div className="mb-6">
											<div className="fw-bold text-gray-600 fs-7">Payment Platform:</div>
											<div className="fw-bolder text-gray-800 fs-6">{PaymentDetails.value.payment_platform.toUpperCase()}</div>
										</div>
										<div className="mb-15">
											<div className="fw-bold text-gray-600 fs-7">Payment Term:</div>
											<div className="fw-bolder fs-6 text-gray-800 d-flex align-items-center">{PaymentDetails.value.duration == "month" ? 30 : PaymentDetails.value.duration == "yearly" ? 365 : null} days
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</>
				}
			</div>
		</div>

		setPrintData(printBody_kot)
	}
	const myPrintFunc = useReactToPrint({
		onBeforeGetContent: beforePrintFunction,
		content: () => { return printComponentRef.current }
	});

	return (
		<>
			<div className="d-flex flex-column flex-root" id="kt_app_root">
				<div className="d-flex flex-column flex-lg-row flex-column-fluid">
					<div className="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">
						<div className="flex-lg-row-fluid me-lg-15">
							<div className="card card-flush">
								{/* <div className="card-header">
									<div className="card-title">
										<h2 className="fw-bolder">Payment Response</h2>
									</div>
								</div> */}
								<div className="card-body pt-0">
									<div class="notice d-flex bg-light-warning rounded border-warning border border-dashed  p-6">
										<i class="ki-duotone ki-information fs-2tx text-warning me-4"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>

										<div class="d-flex flex-stack flex-grow-1 ">
											<div class=" fw-semibold">
												<h4 class="text-gray-900 fw-bold">Thank you for your subscription!</h4>

												<div class="fs-6 text-gray-700 ">We are currently processing your subscription.  In the meantime, feel free to explore our website! <a class="fw-bold" href="https://adoerp.com">Explore Our Website</a>.</div>
											</div>

										</div>
									</div>
								</div>
							</div>
							<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
						</div>
					</div>
				</div>
			</div>
			{/* {
				state_flag == 0 ? null : state_flag == 1 ? <>
					<Redirect to={{ pathname: `/payment-response/failure/${props.match.params.id}` }} />
				</> 
				:
				state_flag == 2 ? <> <Redirect to={{ pathname: `/payment-response/success/${props.match.params.id}` }}/></>
				:
				<> <Redirect to={{ pathname: `/not-found` }}/></>
			} */}

			{/* <div className="d-flex flex-column flex-column-fluid">
				<div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
					<div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
						<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Payment Response
							<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
							<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
						</h1>
						<span class="badge badge-success">{organization_name}</span>
					</div>
				</div>
				<div id="kt_app_content" className="app-content flex-column-fluid">
					<div id="kt_app_content_container" className="app-container container-xxl">
						<div className="card  pt-0 pb-5 px-3 px-xl-5">
							<br />
							{(PaymentDetails!==null && PaymentDetails.data == "found") ?
								<>
									<div className="card-body p-lg-20" id="printDiv">

										<div className="d-flex flex-column flex-xl-row">

											<div className="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0">

												<div className="mt-n1">
													<div class="d-flex flex-stack pb-10">
														<a >
															<img alt="Logo"  src="https://cdn-getlivealerts.s3.amazonaws.com/sites/images/get-live-alerts.png" class="h-40px  logo" />
														</a>
													</div>

													<div className="m-0">

														<div className="fw-bolder fs-3 text-gray-800 mb-8">Invoice</div>


														<div className="row g-5 mb-11">

															<div className="col-sm-6">

																<div className="fw-bold fs-7 text-gray-600 mb-1">Date:</div>

																<div className="fw-bolder fs-6 text-gray-800">{moment(PaymentDetails.value.starting_date).format('DD MMMM YYYY')}</div>

															</div>

															{
																props.match.params.response == "success" ?
																	<div className="col-sm-6">

																		<div className="fw-bold fs-7 text-gray-600 mb-1">Expiry Date:</div>
																		<div className="fw-bolder fs-6 text-gray-800 d-flex align-items-center flex-wrap">
																			<span className="pe-2">{moment(PaymentDetails.value.ending_date).format('DD MMMM YYYY')}</span>
																			<span className="fs-7 text-danger d-flex align-items-center">
																				<span className="bullet bullet-dot bg-danger me-2"></span>Expiry in {moment(PaymentDetails.value.ending_date).diff(moment(), 'days') + 1} days</span>
																		</div>

																	</div>
																	:
																	null
															}

														</div>

														{
															props.match.params.response == "success" ?
																<div className="row g-5 mb-12">

																	<div className="col-sm-6">

																		<div className="fw-bold fs-7 text-gray-600 mb-1">Issued For:</div>

																		<div className="fw-bolder fs-6 text-gray-800">{(PaymentDetails.value.billing_address).full_name}</div>

																		<div className="fw-bold fs-7 text-gray-600">{(PaymentDetails.value.billing_address).city} {(PaymentDetails.value.billing_address).state}
																			<br />{(PaymentDetails.value.billing_address).country} </div>

																	</div>


																	<div className="col-sm-6">

																		<div className="fw-bold fs-7 text-gray-600 mb-1">Issued By:</div>


																		<div className="fw-bolder fs-6 text-gray-800">CodeLab Inc.</div>


																		<div className="fw-bold fs-7 text-gray-600">9858 South 53rd Ave.
																			<br />Matthews, NC 28104</div>

																	</div>

																</div>
																:
																null
														}
														<div className="flex-grow-1">
															<div className="table-responsive border-bottom mb-9">
																<table className="table mb-3">
																	<thead>
																		<tr className="border-bottom fs-6 fw-bolder text-gray-400">
																			<th className="min-w-175px pb-2">Product</th>
																			<th className="min-w-70px text-end pb-2">Quantity</th>
																			<th className="min-w-70px text-end pb-2">Unit Price</th>
																			<th className="min-w-100px text-end pb-2">Amount</th>
																		</tr>
																	</thead>
																	<tbody>
																		<tr className="fw-bolder text-gray-700 fs-5 text-end">
																			<td className="d-flex align-items-center pt-6">
																				<i className="fa fa-genderless text-danger fs-2 me-2"></i>{PaymentDetails.value.product_name}-{PaymentDetails.value.subscription_name}
																			</td>
																			<td className="pt-6">{JSON.parse(PaymentDetails.value.billing_details).quantity}</td>
																			<td className="pt-6">{PaymentDetails.value.currency == "EUR" ? "€" : (PaymentDetails.value.currency == "USD") ? "$" : (PaymentDetails.value.currency == "INR") ? "₹" : null} {JSON.parse(PaymentDetails.value.billing_details).price}</td>
																			<td className="fw-bold text-gray-700 fs-5 text-end">{PaymentDetails.value.currency == "EUR" ? "€" : (PaymentDetails.value.currency == "USD") ? "$" : (PaymentDetails.value.currency == "INR") ? "₹" : null} {JSON.parse(PaymentDetails.value.billing_details).total_price}</td>
																		</tr>
																		{JSON.parse(PaymentDetails.value.billing_details).Selected_Add_on_list.map((obj,index) =>
																		{
																			return(
																			<tr className="fw-bolder text-gray-700 fs-5 text-end">
																				<td className="d-flex align-items-center pt-6">
																					<i className="fa fa-genderless text-danger fs-2 me-2"></i>{obj.Add_on_feature}-AddOn
																				</td>
																				<td className="pt-6">{obj.quantity}</td>
																				<td className="pt-6">{PaymentDetails.value.currency == "EUR" ? "€" : (PaymentDetails.value.currency == "USD") ? "$" : (PaymentDetails.value.currency == "INR") ? "₹" : null} {obj.price}</td>
																				<td className="fw-bold text-gray-700 fs-5 text-end">{PaymentDetails.value.currency == "EUR" ? "€" : (PaymentDetails.value.currency == "USD") ? "$" : (PaymentDetails.value.currency == "INR") ? "₹" : null}{obj.price*obj.quantity}</td>
																			</tr>		
																		);		
																		}
																		)}
																	</tbody>
																</table>
															</div>
															<div className="d-flex justify-content-end">
																<div className="mw-300px">
																{PaymentDetails.value.amount_subtotal!=undefined
																&&
																<div className="d-flex flex-stack mb-3">
																		<div className="fw-bold pe-10 text-gray-600 fs-7">Subtotal:</div>
																		<div className="text-end fw-bolder fs-6 text-gray-800">{PaymentDetails.value.currency == "EUR" ? "€" : (PaymentDetails.value.currency == "USD") ? "$" : (PaymentDetails.value.currency == "INR") ? "₹" : null} {PaymentDetails.value.amount_subtotal}</div>
																	</div>
																}
																	{
																		PaymentDetails.value.discount!=undefined &&
																		<div className="d-flex flex-stack mb-3">
																			<div className="fw-bold pe-10 text-gray-600 fs-7">Discount:</div>
																			<div className="text-end fw-bolder fs-6 text-gray-800">-{PaymentDetails.value.currency == "EUR" ? "€" : (PaymentDetails.value.currency == "USD") ? "$" : (PaymentDetails.value.currency == "INR") ? "₹" : null} {PaymentDetails.value.discount}</div>
																		</div>
																	}
																	<div className="d-flex flex-stack">
																		<div className="fw-bold pe-10 text-gray-600 fs-7">Total</div>
																		<div className="text-end fw-bolder fs-6 text-gray-800">{PaymentDetails.value.currency == "EUR" ? "€" : (PaymentDetails.value.currency == "USD") ? "$" : (PaymentDetails.value.currency == "INR") ? "₹" : null} {PaymentDetails.value.payment_amount}</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className="m-0">
												<div className="d-print-none border border-dashed border-gray-300 card-rounded h-lg-100 min-w-md-350px p-9 bg-lighten">
													<div className="mb-8">
														{
															(PaymentDetails.value.payment_status == "Verified") ?
																<span className="badge badge-light-success rounded">Payment {PaymentDetails.value.payment_status}</span>
																:
																<span className="badge badge-light-warning rounded">Payment Status Pending</span>
														}
													</div>
													<h6 className="mb-8 fw-boldest text-gray-600 text-hover-primary">PAYMENT DETAILS</h6>
													<div className="mb-6">
														<div className="fw-bold text-gray-600 fs-7">Email:</div>
														<div className="fw-bolder text-gray-800 fs-6">{user.email}</div>
													</div>
													<div className="mb-6">
														<div className="fw-bold text-gray-600 fs-7">Payment Platform:</div>
														<div className="fw-bolder text-gray-800 fs-6">{PaymentDetails.value.payment_platform.toUpperCase()}</div>
													</div>
													<div className="mb-6">
														<div className="fw-bold text-gray-600 fs-7">Start Date:</div>
														<div className="fw-bolder text-gray-800 fs-6">{moment(PaymentDetails.value.starting_date).format('DD MMMM YYYY')}</div>
													</div>
													<div className="mb-6">
														<div className="fw-bold text-gray-600 fs-7">End Date:</div>
														<div className="fw-bolder text-gray-800 fs-6">{moment(PaymentDetails.value.ending_date).format('DD MMMM YYYY')}</div>
													</div>
													<div className="mb-15">
														<div className="fw-bold text-gray-600 fs-7">Payment Term:</div>
														<div className="fw-bolder fs-6 text-gray-800 d-flex align-items-center">{PaymentDetails.value.duration == "month" ? 30 : PaymentDetails.value.duration == "yearly" ? 365 : null} days
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									{
										(PaymentDetails.value.payment_status == "Verified") ?
											<div className="d-flex flex-center flex-row-fluid pt-12">
												<button type="button" className="btn btn-sm btn-info me-3 rounded" onClick={(e) => { myPrintFunc(); }} >Print Invoice</button>

											</div>
											:
											<div className="d-flex flex-center flex-row-fluid pt-12">
												<Link type="button" to="/" className="btn btn-sm btn-primary me-3">Return To Homepage</Link>
											</div>
									}
									<div style={{display: "none"}}>
										<PrintComponent ref={printComponentRef} data={printData}/>
									</div>
								</>

								:
								<>
									{(state == 2) ?
										<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
										:
										<>
											<div className="flex-lg-row-fluid me-lg-15">
												<div className="card card-flush pt-3 mb-5 mb-lg-10">
													<div className="card-header">
														<div className="card-title">
															<h2 className="fw-bolder">Payment Response</h2>
														</div>
													</div>
													<div className="card-body pt-0">
														<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
															<div className="d-flex flex-stack flex-grow-1">
																<div className="fw-bold">
																	<h4 className="text-gray-800 fw-bolder">Something went wrong.</h4>
																	<div className="fs-6 text-gray-600">
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</>
									}
								</>
							}
							<br />
						</div>
					</div>
				</div>
			</div> */}
		</>);

};

class PrintComponent extends React.Component {
	render() {
		return (
			<div>{this.props.data}</div>
		)
	}
};

export default PaymentResponse;
