import React, { lazy, Suspense, useState, useEffect, useRef} from 'react';

import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';

import Select from 'react-select';
import $ from 'jquery';
import {setCookie,getCookie} from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js";
import { useTheme } from "./theme-provider.js"
import PageError from '../common_component/PageError.js';

function AddAddon(props) {
	let [add_date_time, setadd_date_time] = useState("");
	let [duration1, setDuration1] = useState("month");
	let [duration2, setDuration2] = useState("");
	let [username, setUsername] = useState("");
	let [product_name, setproduct_name] = useState("");
	let [product_id, setproduct_id] = useState("");
	let [subscription_name, setsubscription_name] = useState("");
	let [subscription_id, setsubscription_id] = useState("");
	let [add_status, setadd_status] = useState("");
	let [start_date, setstart_date] = useState("");
	let [end_date, setend_date] = useState("");
	const [PurchaseDetails, setPurchaseDetails] = useState([]);
	const [AddressDetails, setAddressDetails] = useState([]);
	let [state, setstate] = useState(2);

	let [subscription, set_subscription] = useState("");
	const [SubscriptionDetails, setSubscriptionDetails] = useState([]);
	const [Invoicedetails, setInvoicedetails] = useState([]);
	const [invoicedraft, setinvoicedraft] = useState([]);
	let [payment_gateway_id, setpayment_gateway_id] = useState("");
	let [payment_platform, setpayment_platform] = useState("");
	let [timezone_change, set_timezone_change] = useState("+00:00");
	let [currency, setCurrency] = useState("USD");
	const [Add_on, setAdd_on] = useState("");
	const [Add_on1, setAdd_on1] = useState("");
	const [Diff_elem, setDiff_elem] = useState("");
	const [Add_on_details, setAdd_on_details] = useState([]);
	const [Add_on_list, setAdd_on_list] = useState([]);
	const [Add_on_list1, setAdd_on_list1] = useState([]);
	const [Selected_Add_on_list, setSelected_Add_on_list] = useState([]);
	const [All_add_on, setAll_add_on] = useState([]);
	const [addon_price, setaddon_price] = useState("");
	const [Total_addon_price, setTotal_addon_price] = useState("");
	let [discounted_duration,setdiscounted_duration] = useState("");
	let [total_duration,settotal_duration] = useState("");

	let [payment_mode, setpayment_mode] = useState("Non Recurring");
	const [PaymentDetails, setPaymentDetails] = useState([]);
	const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);
	let [referal_code,setreferal_code] = useState("");
	let [Organization_admin, setOrganization_admin] = useState("");

	let [discounted_price,setdiscounted_price] = useState("");
	let [coupon_code,setcoupon_code] = useState("");
	let [coupon_code2,setcoupon_code2] = useState("");
	let [discount_type,setdiscount_type] = useState("");
	let [discount_value,setdiscount_value] = useState(0);

	var page_name = "view-subscription";

	const { user, organization_id, user_role, organization_name } = useAuthData();
	const { theme, dropdownStyleDark } = useTheme();

	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	useEffect(() => {
		async function getaddonfeatures() {
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-add-on-features`, { method: 'POST', body: JSON.stringify({"email": user.email,"page_name":page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();	
			setAll_add_on(body)
        }
        user!==null &&  user.email &&  getaddonfeatures();

		async function getsubscriptiondetails(valus) {
			var object = {};
			const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-subscription-details/${subscription_id}`, { method : 'GET', headers: {"Content-type": "application/json; charset=UTF-8", "token" : getCookie("access_token") } }
				);
				const body = await response.json();

				body.map((obj, index) => {
					var json = JSON.parse(obj.subscription_charges);
					//var json3 = JSON.parse(obj.add_on_features);
					json.map((obj2, index2) => {
						if(currency == obj2.currency || (obj2.default == "Yes" && currency == "default") )
						{
							obj.currency = obj2.currency;
						}
					});
				})
				if(body.length>0)
				{
					set_subscription(body)
				}
			}
			(user!==null &&  user.email &&  subscription_id!="") && getsubscriptiondetails()
		}, [user,subscription_id])

		/*useEffect(() => {
			if(All_add_on.length>0 && subscription!="")
			{
				subscription.map((obj, index) => {
					var json3 = JSON.parse(obj.add_on_features);
					let arr=[];
					json3.map((obj3, index3) => {
						arr.push({value:obj3.feature,label:All_add_on.filter(option => option.unique_id == obj3.feature)[0].feature_name})
					})
					setAdd_on_list1(json3);
					setAdd_on_list(arr);
				})
			}
		
		},[user,All_add_on,subscription])*/

	useEffect(() => {
		async function getaccountdetails() {
			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-organization-timezone-information`, { method: 'POST', body: JSON.stringify({ "email": user.email,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();

			//console.log(body)
			if (body.length > 0) {
				if (body[0].timezone_values != "" && body[0].timezone_value != null) {
					set_timezone_change(body[0].timezone_value)
				}
			}
		}
		user!==null &&  user.email &&  getaccountdetails();
	}, [user])

	useEffect(() => {

		async function getOrganizationAdmin() {

			var object = {};
			object.email = user.email;


			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-Organization-Admin`, { method: 'POST', body: JSON.stringify({ "email": user.email,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();
			console.log(body);
			if(body.operation=="success")
			{
				setOrganization_admin(body.data[0].user_id);
			}
		}
		user!==null &&  user.email &&  getOrganizationAdmin();

		async function getpurchasedetails() {

			var object = {};
			object.email = user.email;
			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-purchase-details`, { method: 'POST', body: JSON.stringify({"id": props.match.params.id, "email": user.email,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();
			console.log(body);
			if (body.length > 0) {
				setproduct_id(body[0].product_id)
				var b = JSON.stringify(body[0].billing_details);
				var billing_details = b.replace(/\\/g, '');
				var billing_details1 = billing_details.replace(/"""/g, '');
				if (billing_details1.startsWith('"')) {
					billing_details1 = billing_details1.slice(1, -1);
				}
				//console.log(JSON.parse(billing_details1))
				let arr=[];
				for(let i=0;i<body[0].addon_details.length;i++)
				{
					let add_on=JSON.parse(body[0].addon_details[i].billing_details).Selected_Add_on_list;
					for(let j=0;j<add_on.length;j++)
					{
						console.log((body[0].addon_details[i].add_date_time))
						add_on[j].add_date_time=(body[0].addon_details[i].add_date_time);
						arr.push(add_on[j])
					}
				}
				setAdd_on_details(arr)
				//setpayment_mode(body[0].payment_type)
				setPurchaseDetails(JSON.parse(billing_details1));
				setPaymentDetails(JSON.parse(body[0].payment_response));
				setAddressDetails(JSON.parse(body[0].billing_address));
				setproduct_name(body[0].product_name)
				setsubscription_name(body[0].subscription_name)
				setsubscription_id(body[0].subscription_id);
				setadd_date_time(body[0].add_date_time);
				setadd_status(body[0].add_status);
				setpayment_gateway_id(body[0].payment_gateway_subscription_id);
				setpayment_platform(body[0].payment_platform);
				setstart_date(moment(body[0].starting_date).zone(timezone_change).format("DD MMMM YYYY"))
				setend_date(moment(body[0].ending_date).zone(timezone_change).format("DD MMMM YYYY"))
				setstate(1);

			}
			else {
				setstate(0)
			}
		}
		(user!==null &&  user.email &&  timezone_change != "+00:00") && getpurchasedetails();

	}, [user, timezone_change])

	useEffect(() => {
		if(state==1 && All_add_on.length>0 && subscription!="" && end_date!="")
		{
			let now = new Date();
			now = moment(now);
			let end = moment(end_date).format('YYYY-MM-DD');
			var duration_date = moment.duration(now.diff(end));
			let dur=-duration_date.asDays();
			let duration="";
			if(dur<=30)
			{
				duration="mon";
			}
			else if(dur<=90)
			{
				duration="3 mon";
			}
			else if(dur<=180)
			{
				duration="6 mon";
			}
			else if(dur<=365)
			{
				duration="year";
			}
			setDuration2(duration)
			subscription.map((obj, index) => {
				if(obj.add_on_features!=="")
				{
					var json3 = JSON.parse(obj.add_on_features).filter(option => option.duration == duration)[0].features;
					let arr=[];
					json3.map((obj3, index3) => {
						arr.push({value:obj3.feature,label:All_add_on.filter(option => option.unique_id == obj3.feature)[0].feature_name})
					})
					setAdd_on_list1(json3);
					setAdd_on_list(arr);
				}
			})
		}
		
	}, [state,All_add_on,subscription,end_date])
	//console.log(setAddressDetails);

	/*useEffect(() => {
		async function get_invoice_details() {

			var object = {};
			object.email = user.email;
			if (payment_platform == "stripe-euro" || payment_platform == "stripe-usd") {
				const response = await fetch(
					`${process.env.REACT_APP_SERVER_URL}/get-invoice-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "sub_id": payment_gateway_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
				);
				const body = await response.json();

				body.value.invoice.map((obj, index) => {
					//END DATE
					var temp_date = body.value.invoice[index].period_end;
					body.value.invoice[index].period_end = moment.unix(body.value.invoice[index].period_end).zone(timezone_change).format("DD/MM/YYYY");
					
					body.value.invoice[index].period_end1 = moment.unix(temp_date).utc().format("DD MMMM YYYY") + " (UTC)";
					//START DATE
					temp_date = body.value.invoice[index].period_start;
					body.value.invoice[index].period_start = moment.unix(body.value.invoice[index].period_start).zone(timezone_change).format("DD/MM/YYYY");
					
					body.value.invoice[index].period_start1 = moment.unix(temp_date).utc().format("DD MMMM YYYY") + " (UTC)";

				})
				body.value.invoicedraft.map((obj, index) => {
					//END DATE
					var temp_date = body.value.invoicedraft[index].period_end;
					body.value.invoicedraft[index].period_end = moment.unix(body.value.invoicedraft[index].period_end).zone(timezone_change).format("DD/MM/YYYY");
					
					body.value.invoice[index].period_end1 = moment.unix(temp_date).utc().format("DD MMMM YYYY")  + " (UTC)";
					//START DATE
					temp_date = body.value.invoicedraft[index].period_start;
					body.value.invoicedraft[index].period_start = moment.unix(body.value.invoicedraft[index].period_start).zone(timezone_change).format("DD/MM/YYYY");
					
					body.value.invoice[index].period_start1 = moment.unix(temp_date).utc().format("DD MMMM YYYY") + " (UTC)";
				})
				//console.log(body)
				setInvoicedetails(body.value.invoice)
				setinvoicedraft(body.value.invoicedraft)
			}
			else if (payment_platform == "razorpay") {
				const response = await fetch(
					`${process.env.REACT_APP_SERVER_URL}/get-invoice-details2`, { method: 'POST', body: JSON.stringify({ "email": user.email, "sub_id": payment_gateway_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
				);
				const body = await response.json();
				// console.log(body)
				body.value.invoice.map((obj, index) => {
					//END DATE
					var temp_date = body.value.invoice[index].period_end;
					body.value.invoice[index].period_end = moment.unix(body.value.invoice[index].period_end).zone(timezone_change).format("DD MMMM YYYY");
					
					body.value.invoice[index].period_end1 = moment.unix(temp_date).utc().format("DD MMMM YYYY") + " (UTC)";
					
					
					//START DATE
					temp_date = body.value.invoice[index].period_start;
					body.value.invoice[index].period_start = moment.unix(body.value.invoice[index].period_start).zone(timezone_change).format("DD MMMM YYYY");
					
					body.value.invoice[index].period_start1 = moment.unix(temp_date).utc().format("DD MMMM YYYY")  + " (UTC)";

				})
				body.value.invoicedraft.map((obj, index) => {
					//END DATE
					var temp_date = body.value.invoicedraft[index].period_end;
					body.value.invoicedraft[index].period_end = moment.unix(body.value.invoicedraft[index].period_end).zone(timezone_change).format("DD MMMM YYYY");
					
					body.value.invoicedraft[index].period_end1 = moment.unix(temp_date).utc().format("DD MMMM YYYY")  + " (UTC)";
					
					//START DATE
					temp_date = body.value.invoicedraft[index].period_start;
					body.value.invoicedraft[index].period_start = moment.unix(body.value.invoicedraft[index].period_start).zone(timezone_change).format("DD/MM/YYYY");
					
					
					body.value.invoicedraft[index].period_start1 = moment.unix(temp_date).utc().format("DD MMMM YYYY")  + " (UTC)";

				})
				//console.log(body)
				setInvoicedetails(body.value.invoice)
				setinvoicedraft(body.value.invoicedraft)
			}
		}
		payment_gateway_id && payment_platform && get_invoice_details();
	}, [payment_gateway_id, payment_platform]);
	*/

	function cancel_subscription(e) {

		var sub_id = payment_gateway_id;
		var payment_type = payment_platform;

		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}
		var values = { "sub_id": sub_id, "payment_gateway": payment_type }
		//console.log(payment_type);
		let cancel_type = document.querySelector('input[name="cancel_radio"]:checked').value;

		if (cancel_type == "") {
			swal("Oops!", "Please select which type of subscription cancellation you want!", "error");
			return false;
		}
		else if (!isNaN(cancel_type)) {
			swal("Oops!", "Please select which type of subscription cancellation you want!", "error");
			return false;
		}

		if (cancel_type == "billing_period" && (payment_type == "stripe-euro" || payment_type == "stripe-usd")) {

			axios.post(`${process.env.REACT_APP_SERVER_URL}/cancel-subscription`, values, headers).then((res) => {
				if (res.data && res.data.operation == "success") {
					swal("Great!", "Subscription will be cancelled after the billing period!", "success").then((value) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			});

		}
		else if (cancel_type == "now" && (payment_type == "stripe-euro" || payment_type == "stripe-usd")) {

			axios.post(`${process.env.REACT_APP_SERVER_URL}/cancel-subscription-now`, values, headers).then((res) => {
				if (res.data && res.data.operation == "success") {
					swal("Great!", "Subscription cancelled!", "success").then((value) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			});

		}
		else if (cancel_type == "billing_period" && payment_type == "razorpay") {

			axios.post(`${process.env.REACT_APP_SERVER_URL}/cancel-subscription-razorpay`, values, headers).then((res) => {
				if (res.data && res.data.operation == "success") {
					swal("Great!", "Subscription will be cancelled after the billing period!", "success").then((value) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			});

		}
		else if (cancel_type == "now" && payment_type == "razorpay") {

			axios.post(`${process.env.REACT_APP_SERVER_URL}/cancel-subscription-now-razorpay`, values, headers).then((res) => {
				if (res.data && res.data.operation == "success") {
					swal("Great!", "Subscription cancelled!", "success").then((value) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			});
		}
		else if (payment_type == "Trial") {

			axios.post(`${process.env.REACT_APP_SERVER_URL}/cancel-subscription-trial`, values, headers).then((res) => {
				if (res.data && res.data.operation == "success") {
					swal("Great!", "Subscription cancelled!", "success").then((value) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			});

		}
		else {
			swal("Oops!", "Please select which type of subscription cancellation you want!", "error");
			return false;
		}
	}

	const handleInputChange = (data) => {
		let values1 = [...Selected_Add_on_list];
		let index="";
		let temp=-1;
		if(data.filter(x => Add_on1.indexOf(x) === -1).length>0)
		{
			index=data.filter(x => Add_on1.indexOf(x) === -1)[0];
			temp=0;
		}
		else
		{
			index=Add_on1.filter(x => data.indexOf(x) === -1)[0];
			temp=1;
		}
		if(temp==0)
		{
			let Addon=All_add_on.filter(option => option.unique_id == index)[0];
			let update_Addon=Add_on_list1.filter(option => option.feature == index)[0];
			values1.push({Add_on_id:index,Add_on_feature:Addon.feature_name,price:update_Addon.value,unit:update_Addon.unit,quantity:"1"})
		}
		else
		{
			values1 = values1.filter(function( obj ) {
				return obj.Add_on_id !== index;
			  });
		}
		let subtotal=0;
		for(let i=0;i<values1.length;i++)
			{
				subtotal=subtotal+parseInt(values1[i].price)*parseInt(values1[i].quantity);
			}
			let now = new Date();
			now = moment(now);
			let end = moment(start_date).format('YYYY-MM-DD');
			var duration_date = moment.duration(now.diff(end));
			var total_duration = moment(end_date).diff(moment(start_date), 'days');
			settotal_duration(total_duration);
			setdiscounted_duration(Math.ceil(duration_date.asDays()));
			let discount=0;
			console.log(duration_date.asDays());
			if(duration_date.asDays()>1)
			{
				discount =(Math.ceil(duration_date.asDays())*(subtotal/total_duration));
			}
			
			setdiscount_type("Amount");
			let price=subtotal-Math.round(discount*100)/100
			setdiscount_value(Math.round(discount*100)/100);

			setdiscounted_price(price);
			setaddon_price(subtotal);
			setSelected_Add_on_list(values1)
		/*console.log(data)
		console.log(All_add_on)		
		let arr=[];
		data.map((obj3, index3) => {
			let Addon=All_add_on.filter(option => option.unique_id == obj3)[0];
			let update_Addon=Add_on_list1.filter(option => option.feature == obj3)[0];
			arr.push({value:obj3,label:Addon.feature_name,price:update_Addon.value,unit:update_Addon.unit,quantity:"1"})
		})
		setSelected_Add_on_list(arr)
		*/
	}
	
	const coupon_code1 =(e)=>{
		e.preventDefault();
		
		var object = {};
		let dur="";
		if(duration1=="mon")
		{
			dur="month";
		}
		else
		{
			dur="year";
		}
		object.id=subscription[0].product_id+"("+dur+")";
		object.subscription_type="Paid"
		object.coupon_code=coupon_code;
		object.page_name=page_name;
	
		var headers = {
		  headers: {
			"Content-Type": "application/json",
			"Accept": "*/*",
			"token" : getCookie("access_token")
		  }
		}
		setMainSubmitButtonState(true);		
		axios.post(`${process.env.REACT_APP_SERVER_URL}/check-coupon-availabilty`,object, headers).then((res) => {
			setMainSubmitButtonState(false);
			if(res.data.operation == "success")
			{
				if(res.data.data == "found")
				{
					swal("Great!", res.data.value.coupon_message+"!", "success").then((value) => {
						
					});
					setcoupon_code2(res.data.value.coupon_code);
					setdiscount_type(res.data.value.discount_type)
					console.log(res.data.value.discount)
					setdiscount_value(res.data.value.discount)
					let price1="";
					if(res.data.value.discount_type=="Percentage")
					{
						price1 = (addon_price)-((addon_price)*res.data.value.discount)/100;
					}
					else if(res.data.value.discount_type=="Amount")
					{
						price1 = ((addon_price)-res.data.value.discount)
					}
					setdiscounted_price(price1)	
				}
				else if(res.data.data == "not found")
				{
					swal("Oops!", "Invalid coupon", "error").then((res) => {
						//window.location.reload();
						setdiscount_type("");
						setdiscount_value("");
					});
				}
				else if(res.data.data == "not available for this product")
				{
					swal("Oops!", "This coupon is not available for this product", "error").then((res) => {
						//window.location.reload();
						setdiscount_type("");
						setdiscount_value("");
					});
				}
				else if(res.data.data == "not available for this user")
				{
					swal("Oops!", "This coupon is only available for selected users", "error").then((res) => {
						//window.location.reload();
						setdiscount_type("");
						setdiscount_value("");
					});
				}
				else if(res.data.data == "not available" && res.data.subscription_type=="Paid")
				{
					swal("Oops!", "This coupon is not available for paid subscription", "error").then((res) => {
						//window.location.reload();
						setdiscount_type("");
						setdiscount_value("");
					});
				}
				else if(res.data.data == "not available" && res.data.subscription_type=="Trial")
				{
					swal("Oops!", "This coupon is not available for trial subscription", "error").then((res) => {
						//window.location.reload();
						setdiscount_type("");
						setdiscount_value("");
					});
				}
										
			}
			else
			{
				if(res.data.message != "danger")
				{
					swal("Oops!", res.data.message, "error").then((res) => {
						//window.location.reload();
						setdiscount_type("");
						setdiscount_value("");
					});
				}
				else
				{
					swal("Oops!", "Invalid coupon", "error").then((res) => {
						//window.location.reload();
						setdiscount_type("");
						setdiscount_value("");
					});
				}
			}
		})
		.catch(function (error) {
			swal("Oops!", "something went wrong", "error").then((res) => {
				//window.location.reload();
				setdiscount_type("");
				setdiscount_value("");
			});
		});
	}

	const Count_input_change = (data) => {
		const values1 = [...Selected_Add_on_list];
		let subtotal=0;
		for(let i=0;i<values1.length;i++)
			{
				subtotal=subtotal+parseInt(values1[i].price)*parseInt(values1[i].quantity);
			}
		setaddon_price(subtotal);
		let discount = 0;
		if(discounted_duration>1)
		{
			discount =(Math.ceil(discounted_duration.asDays())*(subtotal/total_duration));
		}
		let price=subtotal-Math.round(discount*100)/100
		setdiscount_value(Math.round(discount*100)/100);
		setdiscounted_price(price);
	}

	const purchase_addon=()=>{

		if(user.email!=Organization_admin)
		{
			swal("Permission Denied!", "You don't have any permission to purchase. Please reach out to your Admin.", "warning").then((value) => {
				
			});
			return false;
		}
		let object={};
		object.flag="addon";
		object.subscription_id = props.match.params.id;
		console.log(payment_mode)
		object.payment_mode =payment_mode;
		if(duration2=="mon")
		{
		object.duration ="month";
		}
		else if(duration2=="year")
		{
		object.duration ="yearly";
		}
		object.discount_type = discount_type;
		object.discount_value = discount_value;
		object.referal_code =referal_code;
		/*
		if(object.discount!="")
		{
			object.discount_type = "Percentage";
		}
		else
		{
			object.discount_type = discount_type;
		}
		if(object.discount!="")
		{
			object.discount_value = object.discount+discount_value;
			object.discount_value = parseFloat(object.discount_value).toFixed(2);
		}
		else
		{
			object.discount_value = discount_value;
		}
		*/
		object.currency = currency;
		object.purchase_flag = "Purchase";
		let now = new Date();
		now = moment(now);
		let end = moment(end_date).format('YYYY-MM-DD');
		var duration_date = moment.duration(now.diff(end));
		object.starting_date = moment.utc().format('YYYY-MM-DD');
		let dur=-duration_date.asDays();
		if(dur<=30)
		{
			object.ending_date = moment.utc().add(30, 'days').format('YYYY-MM-DD');
		}
		else if(dur<=90)
		{
			object.ending_date = moment.utc().add(90, 'days').format('YYYY-MM-DD');
		}
		else if(dur<=180)
		{
			object.ending_date = moment.utc().add(180, 'days').format('YYYY-MM-DD');
		}
		else if(dur<=365)
		{
			object.ending_date = moment.utc().add(365, 'days').format('YYYY-MM-DD');
		}
		//object.ending_date = moment.utc().format('YYYY-MM-DD');
		object.ending_date = moment(end_date).format('YYYY-MM-DD');
		object.uid = props.match.params.id;
		object.billing_address = AddressDetails;
		object.subscription_id = subscription_id;
		object.product_id = subscription[0].product_id;
		console.log(object);
		if (object.currency == "USD") {
			paymentHandler_stripe_usd(object);
		}
	}

	const paymentHandler_stripe_usd = async (object) => {
		var headers = {
		  headers: {
			"Content-Type": "application/json",
			"Accept": "*/*",
			"token" : getCookie("access_token")
		  }
		}
		object.response = "";
		object.payment_gateway = "stripe-usd";
		object.organization_id = organization_id;
		object.upgrade = 1;
		object.Selected_Add_on_list =Selected_Add_on_list;
		object.addon_price =addon_price;
		if(discounted_price!="")
		{
			object.price = discounted_price;
		}
		else
		{
			object.price =addon_price;
		}
		setMainSubmitButtonState(true)
		axios.post(`${process.env.REACT_APP_SERVER_URL}/purchase-plan-addon`,object, headers).then(async (res) => {
			if(res.data && res.data.operation == "success")
			{
				const API_URL = process.env.REACT_APP_SERVER_URL;
				const orderUrl = `${API_URL}/create-session-subscription-payment`;
				var payment_object = {};
				payment_object.id = res.data.id;
				payment_object.email = user.email;
				
				
				object.id = res.data.id;
				object.email = user.email;
				//object.upgrade = 1;
				const response = await axios.post(orderUrl,object);

				//console.log(response.data.message);
				if(response.data.message.id)
				{
					setMainSubmitButtonState(false)	
					
					var stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_Test);
					$(document).ready(function() {
					  setTimeout(function() {
						console.log(response.data.message.id,process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_Test)
						stripe.redirectToCheckout({
							sessionId: response.data.message.id
						}).then(function (result) {
							if (result.error) {
								console.log()
								alert(result.error.message);
							  }
						});
					  }, 1000);
					});
				}
				else
				{
					swal("Oops!", "Something went wrong!", "error");
				}
				
			}
			else
			{
				swal("Oops!", "Something went wrong!", "error");
			}
		});
	};

	return (
		<>
			<div className="d-flex flex-column flex-column-fluid">
				<div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
					<div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
						<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Add Addon
							<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
							<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
						</h1>
						<span class="badge badge-success">{organization_name}</span>
					</div>
				</div>
				<div id="kt_app_content" className="app-content flex-column-fluid">
					<div id="kt_app_content_container" className="app-container container-xxl">
					{state == 1 ?
						<div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
							<br />
							<div className="d-flex flex-column flex-xl-row">
								<div className="flex-lg-row-fluid me-lg-15">
								<div className="row">
										<div className="col-lg-8">
											<div className="card-header">
												<div className="card-title">
													<h2 className="fw-bolder">Purchase Details</h2>
												</div>
												<div className="card-toolbar">
													{PurchaseDetails.product_flag == "Trial" ?
														<a href={"/subscription-list/" + PurchaseDetails.product_id} className="btn btn-light-primary rounded">Update Subscription</a>
														:
														<a href={`/purchase/${product_id}`} className="btn btn-light-primary rounded">Update Subscription</a>
													}
												</div>
											</div>

											<div className="card-body pt-3">
												<div className="mb-10">
													<h5 className="mb-4">Billing Details:</h5>
													<div className="row gx-10">
														<div className="col-lg-8">
															{AddressDetails &&
																<table className="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
																	<tr>
																		<td className="text-gray-400">Customer Name:</td>
																		<td className="text-gray-800">{AddressDetails.full_name} {AddressDetails.last_name}</td>
																	</tr>
																	<tr>
																		<td className="text-gray-400">Customer Email:</td>
																		<td className="text-gray-800">
																			{AddressDetails.email}
																		</td>
																	</tr>
																	<tr>
																		<td className="text-gray-400">Address:</td>
																		<td className="text-gray-800">{AddressDetails.city}, {AddressDetails.state},  {AddressDetails.country},  {AddressDetails.pincode}.</td>
																	</tr>
																	<tr>
																		<td className="text-gray-400">Phone:</td>
																		<td className="text-gray-800">{AddressDetails.phone}</td>
																	</tr>
																</table>
															}
														</div>
														{/*<div className="col-lg-6">
															<table className="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0 ms-0 ms-lg-10">
																<tr>
																	<td className="text-gray-400">Subscribed Product:</td>
																	<td className="text-gray-800">
																		{product_name}
																	</td>
																</tr>
																<tr>
																	<td className="text-gray-400">Subscription Fees:</td>
																	{PurchaseDetails.currency == "INR" ?
																		<td className="text-gray-800">₹{PurchaseDetails.price} / {PurchaseDetails.duration}</td>
																		:
																		PurchaseDetails.currency == "USD" ?
																			<td className="text-gray-800">${PurchaseDetails.price} / {PurchaseDetails.duration}</td>
																			:
																			PurchaseDetails.currency == "EUR" ?
																				<td className="text-gray-800">€{PurchaseDetails.price} / {PurchaseDetails.duration}</td>
																				:
																				null
																	}
																</tr>
																<tr>
																	<td className="text-gray-400">Number of User:</td>
																	<td className="text-gray-800">
																		{PurchaseDetails.quantity}
																	</td>
																</tr>
																<tr>
																	<td className="text-gray-400">Total Price:</td>
																	{PurchaseDetails.currency == "INR" ?
																		<td className="text-gray-800">₹{PurchaseDetails.total_price} / {PurchaseDetails.duration}</td>
																		:
																		PurchaseDetails.currency == "USD" ?
																			<td className="text-gray-800">${PurchaseDetails.total_price} / {PurchaseDetails.duration}</td>
																			:
																			PurchaseDetails.currency == "EUR" ?
																				<td className="text-gray-800">€{PurchaseDetails.total_price} / {PurchaseDetails.duration}</td>
																				:
																				null
																	}
																</tr>
																<tr>
																	<td className="text-gray-400">Billing method:</td>
																	<td className="text-gray-800">{PurchaseDetails.duration}</td>
																</tr>
																<tr>
																	<td className="text-gray-400">Currency:</td>
																	<td className="text-gray-800">{PurchaseDetails.currency}</td>
																</tr>
															</table>
														</div>*/}
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-4">
											<div class="m-0">				
												<div class="d-print-none border border-dashed border-gray-300 card-rounded h-lg-100 min-w-md-350px p-9 bg-lighten">
													{/*<h6 class="mb-8 fw-bolder text-gray-600 text-hover-primary">PAYMENT DETAILS</h6>
													<div class="mb-6">
														<div class="fw-semibold text-gray-600 fs-7">Total Payment:</div>
														<div class="fw-bold text-gray-800 fs-6">{PaymentDetails.currency == "usd" &&"$"}{PaymentDetails.amount_total/100}</div>
													</div>
													<div class="mb-6">
														<div class="fw-semibold text-gray-600 fs-7">Payment Currency:</div>
														<div class="fw-bold text-gray-800 fs-6">{PaymentDetails.currency == "usd" &&"USD"} </div>
													</div>
													<div class="mb-15">
														<div class="fw-semibold text-gray-600 fs-7">Payment Status:</div>
														<div class="fw-bold fs-6 text-gray-800 d-flex align-items-center"><br/><span class="badge badge-light-success me-2">Approved</span></div>
													</div>*/}
													<h6 class="mb-8 fw-bolder text-gray-600 text-hover-primary">Subscricption Details</h6>
													<div class="mb-6">
														<div class="fw-semibold text-gray-600 fs-7">Start Date</div>
														<div class="fw-bold fs-6 text-gray-800">{start_date}
														</div>
													</div>
													<div class="mb-6">
														<div class="fw-semibold text-gray-600 fs-7">End Date:</div>
														<div class="fw-bold text-gray-800 fs-6">{end_date}</div>
													</div>
													<div class="m-0">
														<div class="fw-semibold text-gray-600 fs-7">Duration:</div>
														<div class="fw-bold fs-6 text-gray-800 d-flex align-items-center">{moment(end_date).diff(moment(start_date), 'days')} Days &nbsp; <span className="fs-7 text-danger d-flex align-items-center">
														<span className="bullet bullet-dot bg-danger me-2"></span>Expiry in {moment(end_date).diff(moment(), 'days') + 1} days</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
										<div className="card-body pt-3">
											<div className="mb-0">
												<h5 className="mb-4">Subscribed Products:</h5>
												<div className="table-responsive">
													<table className="table align-middle table-row-dashed fs-6 gy-4 mb-0">
														<thead>
															<tr className="border-bottom border-gray-200 text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
																<th className="min-w-125px">Product</th>
																<th className="min-w-125px">Quantity</th>
																<th className="min-w-125px">Unit Price</th>
																<th className="min-w-125px">Total</th>
																<th className="min-w-125px">Date</th>
															</tr>
														</thead>
														<tbody className="fw-bold text-gray-800">
															<tr>
																<td>
																	<label className="w-125px">{product_name}</label>
																</td>
																<td>
																{PurchaseDetails.quantity}(User)
																</td>
																<td>
																{PurchaseDetails.currency == "INR" ?
																	<td className="text-gray-600">₹{PurchaseDetails.price} / {PurchaseDetails.duration}</td>
																	:
																	PurchaseDetails.currency == "USD" ?
																		<td className="text-gray-600">${PurchaseDetails.price} / {PurchaseDetails.duration}</td>
																		:
																		PurchaseDetails.currency == "EUR" ?
																			<td className="text-gray-600">€{PurchaseDetails.price} / {PurchaseDetails.duration}</td>
																			:
																			null
																}
																</td>
																<td>{PurchaseDetails.currency == "INR" ?
																	<span className="fw-bold text-gray-600">₹{PurchaseDetails.total_price} / {PurchaseDetails.duration}</span>
																	:
																	PurchaseDetails.currency == "USD" ?
																		<span className="fw-bold text-gray-600">${PurchaseDetails.total_price} / {PurchaseDetails.duration}</span>
																		:
																		PurchaseDetails.currency == "EUR" ?
																			<span className="fw-bold text-gray-600">€{PurchaseDetails.total_price} / {PurchaseDetails.duration}</span>
																			:
																			null
																}</td>
																<td>
																	{moment(add_date_time).format("lll")}
																</td>
															</tr>
															{Add_on_details.map((obj,index) =>
																{
																	return(
																		<tr>
																			<td>
																				<label className="w-125px">{obj.Add_on_feature}-AddOn</label>
																			</td>
																			<td>
																			{obj.quantity}(User)
																			</td>
																			<td>
																			{PurchaseDetails.currency == "INR" ?
																				<td className="text-gray-600">₹{obj.price}{obj.unit}</td>
																				:
																				PurchaseDetails.currency == "USD" ?
																					<td className="text-gray-600">${obj.price}{obj.unit}</td>
																					:
																					PurchaseDetails.currency == "EUR" ?
																						<td className="text-gray-600">€{obj.price}{obj.unit}</td>
																						:
																						null
																			}
																			</td>
																			<td>{PurchaseDetails.currency == "INR" ?
																				<span className="fw-bold text-gray-600">₹{obj.price*obj.quantity}/ {PurchaseDetails.duration}</span>
																				:
																				PurchaseDetails.currency == "USD" ?
																					<span className="fw-bold text-gray-600">${obj.price*obj.quantity}/ {PurchaseDetails.duration}</span>
																					:
																					PurchaseDetails.currency == "EUR" ?
																						<span className="fw-bold text-gray-600">€{obj.price*obj.quantity}/ {PurchaseDetails.duration}</span>
																						:
																						null
																			}</td>
																			<td>
																				{moment(obj.add_date_time).format("lll")}
																			</td>
																		</tr>
																	);		
																}
															)}
														</tbody>
													</table>
												</div>
											</div><br/>
											<div className="row mb-5">
												<div className="col-md-7 fv-row">
												<h5 className="mb-4">Select Add On Features</h5>
													<Select
														styles={theme=='dark'?dropdownStyleDark:{}}
														name="AddOn"
														id="AddOn"
														isMulti
														className="form-control form-control-solid"
														options={Add_on_list}
														onChange={(e) => {handleInputChange(Array.isArray(e)?e.map(x=>x.value):[]); setAdd_on(Array.isArray(e)?e.map(x=>x.label):[]); setAdd_on1(Array.isArray(e)?e.map(x=>x.value):[]);}}
														placeholder="--Select--"
													/>
												</div>
												{user.email!=Organization_admin && <span class="badge py-3 px-4 fs-7 badge-light-danger">You don't have any permission to purchase. Please reach out to your Admin. </span>}<br/>
												{Selected_Add_on_list.length>0 &&
												<> 
												<div class="table-responsive border-bottom mb-9">
													<table class="table mb-3">
														<thead>
															<tr class="border-bottom border-gray-200 text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
																<th class="min-w-150px">Add On</th>
																<th class="min-w-150px me-3">Count</th>
																<th class="min-w-150px">Price</th>
															</tr>
														</thead>
														<tbody class="fw-bold text-gray-600">
															{Selected_Add_on_list.map((obj,index) =>
																{
																	return(		
																<>
																<tr>
																	<td >
																		<div class="d-flex align-items-center">
																			<div class="d-flex justify-content-start flex-column">
																				<a href="#" class="text-dark fw-bolder text-hover-primary fs-6">{obj.Add_on_feature}</a>
																				<span class="text-muted fw-bold text-muted d-block fs-7">{subscription[0].currency=="USD" &&<>$</>}{obj.price}{obj.unit}</span>
																			</div>
																		</div>
																	</td>
																	<td>
																	<div className="col-md-5 fv-row">
																			<input className="form-control" type="number" min="1" name="quantity"
																			onChange={(event) => { 
																				const values1 = [...Selected_Add_on_list];
																				values1[index].quantity=event.target.value;
																				setSelected_Add_on_list(values1);
																				Count_input_change(event.target.value);
																			}}
																			value={obj.quantity} 
																			/>
																		</div>
																	</td>
																	<td>{subscription[0].currency=="USD" &&<>$</>}{parseInt(obj.quantity)*parseInt(obj.price)}</td>
																</tr>
																</>
																);	
																}
															)}
														</tbody>
													</table>
												</div>
												<div class="d-flex justify-content-end">			
												<div class="mw-300px">
													<div class="d-flex flex-stack mb-3">
														<div class="fw-semibold pe-10 text-gray-600 fs-7">Subtotal:</div>
														<div class="text-end fw-bold fs-6 text-gray-800">{subscription[0].currency=="USD" &&<>$</>}{Math.round(addon_price*100)/100}</div>
													</div>
													<div class="d-flex flex-stack mb-3">
													{discounted_price=="" ?
														<button data-bs-toggle="modal" data-bs-target="#DiscountModal">Apply Coupon</button>
														:
														<>
														<div class="fw-semibold pe-10 text-gray-600 fs-7">Discount</div>
														<div class="text-end fw-bold fs-6 text-gray-800">{subscription[0].currency=="USD" &&<>$</>}{discount_value}</div>
														</>
													}
													</div>
													{discounted_price!="" &&
													<div class="d-flex flex-stack">
														<div class="fw-semibold pe-10 text-gray-600 fs-7">Total</div>
														<div class="text-end fw-bold fs-6 text-gray-800">{subscription[0].currency=="USD" &&<>$</>}{Math.round(discounted_price*100)/100}</div>
													</div>
													}
													{Selected_Add_on_list.length>0 &&
														<button type="button" id="kt_careers_submit_button" className="btn btn-lg btn-success me-3 rounded" onClick={(event) => {purchase_addon();}} data-kt-stepper-action="submit"
														data-kt-indicator={mainSubmitButtonState?"on":"off"}
														disabled={mainSubmitButtonState}
														>
														<span className="indicator-label">Proceed to pay</span>
														<span className="indicator-progress">Please wait...
														<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
														</button>
													}
												</div>
											</div>
											
											</>
												}	
											</div>
										</div>
								</div>
								{/*<div className="flex-column flex-lg-row-auto w-100 w-xl-300px mb-10">
									<div className="card card-flush mb-0" data-kt-sticky="true" data-kt-sticky-name="subscription-summary" data-kt-sticky-offset="{default: false, xl: '200px'}" data-kt-sticky-width="{lg: '250px', xl: '300px'}" data-kt-sticky-left="auto" data-kt-sticky-top="150px" data-kt-sticky-animation="false" data-kt-sticky-zindex="95">
										<div className="card-header">
											<div className="card-title">
												<h2>Summary</h2>
											</div>
										</div>
										<div className="card-body pt-0 fs-6">
											<div className="mb-7">
												<h5 className="mb-4">Product details</h5>
												<div className="mb-0">
													<span className="badge badge-light-info me-2 rounded">{product_name}</span>
													{PurchaseDetails.currency == "INR" ?
														<span className="fw-bold text-gray-600">₹{PurchaseDetails.price} / {PurchaseDetails.duration}</span>
														:
														PurchaseDetails.currency == "USD" ?
															<span className="fw-bold text-gray-600">${PurchaseDetails.price} / {PurchaseDetails.duration}</span>
															:
															PurchaseDetails.currency == "EUR" ?
																<span className="fw-bold text-gray-600">€{PurchaseDetails.price} / {PurchaseDetails.duration}</span>
																:
																null
													}
												</div>
											</div>
											<div className="separator separator-dashed mb-7"></div>
											<div className="mb-10">
												<h5 className="mb-4">Subscription Details</h5>
												<table className="table fs-6 fw-bold gs-0 gy-2 gx-2">
													<tr className="">
														<td className="text-gray-400">Subscription Name:</td>
														<td className="text-gray-800">{subscription_name}</td>
													</tr>
													<tr className="">
														<td className="text-gray-400">Started:</td>
														<td className="text-gray-800">{start_date}</td>
													</tr>
													<tr className="">
														<td className="text-gray-400">Status:</td>
														<td>
															<div className="mb-0">
																{add_status == "Active" ?
																	<span className="badge badge-light-success me-2 rounded">{add_status}</span>
																	:
																	<span className="badge badge-light-danger me-2 rounded">{add_status}</span>
																}
															</div>
														</td>
													</tr>
													<tr className="">
														<td className="text-gray-400">End Date:</td>
														<td className="text-gray-800">{end_date}</td>
													</tr>
												</table>
											</div>
												<div className="mb-7">
													<div className="col-xl-6">
														<a>
															<div className="d-flex flex-column py-2">
																<div className="d-flex align-items-center fs-5 fw-bolder mb-5">Address Details
																</div>
																<div className="fs-6 fw-bold text-gray-600">{AddressDetails.city},{AddressDetails.address_line1},{AddressDetails.landmark}
																<br/>{AddressDetails.state},{AddressDetails.pincode}
																<br/>{AddressDetails.country}</div>
															</div>
															{/*<div className="d-flex align-items-center py-2">
																{(selected_billing_address.length>0 && obj.unique_id==selected_billing_address[0].unique_id)?<button type="reset" data-bs-dismiss="modal" onClick={(event) => { select_address(index);}} className="btn btn-sm btn-primary" disabled>Select</button>
																:
																<button type="reset" data-bs-dismiss="modal" onClick={(event) => { select_address(index);}} className="btn btn-sm btn-primary">Select</button>
																}
																<a type="button" onClick={(event) => { update_address(index);}} className="btn btn-sm btn-light btn-active-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_new_address">Edit</a>
															</div>
														</a>
													</div>
												</div>
											{Selected_Add_on_list.length>0 &&
											<button type="button" id="kt_careers_submit_button" className="btn btn-lg btn-success me-3 rounded" onClick={(event) => {purchase_addon();}} data-kt-stepper-action="submit"
											data-kt-indicator={mainSubmitButtonState?"on":"off"}
											disabled={mainSubmitButtonState}
											>
											<span className="indicator-label">Proceed to pay</span>
											<span className="indicator-progress">Please wait...
											<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
											</button>
											}
											{/*add_status == "Active" ?

												<div className="mb-0">

													<a type="button" data-bs-toggle="modal" id="change_status" data-bs-target="#kt_modal_1" className="btn btn-primary rounded">Cancel Subscription</a>
												</div>
												:
												null
													
										</div>
									</div>
								</div>*/}
							</div>
							<div class="col-xl-6">
								<h4 class="text-gray-800 mb-0">Enter Referral Key</h4><br/>
								{/*<p class="fs-6 fw-semibold text-gray-600 py-4 m-0">Plan your blog post by choosing a topic, creating an outline conduct 
								<br/>research, and checking facts</p>*/}
								<div class="d-flex">
									<input id="kt_referral_link_input" type="text" class="form-control form-control-solid me-3 flex-grow-1" value={referal_code} onChange={(e) => {
									setreferal_code(e.target.value);}}/>
									{/*<button id="kt_referral_program_link_copy_btn" class="btn btn-light btn-active-light-primary fw-bold flex-shrink-0" data-clipboard-target="#kt_referral_link_input">Copy Link</button>*/}
								</div>
							</div>
							<br />
						</div>
						:
						<>
						{state == 2 ?
							<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
							:
							<>
								<div className="flex-lg-row-fluid me-lg-15">
									<div className="card card-flush pt-3 mb-5 mb-lg-10">
										<div className="card-header">
											<div className="card-title">
												<h2 className="fw-bolder">Add Addon features</h2>
											</div>
										</div>
										<div className="card-body pt-0">
											<div className="d-flex align-items-center p-3 mb-2">
											</div>
											<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
												<div className="d-flex flex-stack flex-grow-1">
													<div className="fw-bold">
														<h4 className="text-gray-800 fw-bolder">Something went wrong</h4>
														<div className="fs-6 text-gray-600">
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</>
						}
						</>
					}
					</div>
				</div>
			</div>

			<div className="modal fade" id="DiscountModal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mw-450px">
                    <div className="modal-content">
                        <form className="form" action="#" id="kt_modal_new_address_form">
                            <div className="modal-header" id="kt_modal_new_address_header">
                                <h2>Enter Coupon Information</h2>
                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>

                            <div className="modal-body py-10 px-lg-17">
                                <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
									<div className="d-flex flex-column mb-5 fv-row">
										<div className="col-md-10 fv-row">
											<label className=" fs-5 fw-bold mb-2">Coupon Code</label>
											<div class="d-flex">
												<input type="text" className="form-control" value={coupon_code} onChange={(e) => {
												setcoupon_code(e.target.value);
												}}
												placeholder="ENTER A COUPON" name="coupon_code" id="coupon_code" />
												<button type="submit" className="btn btn-primary btn-sm rounded" onClick={(e) => {coupon_code1(e)}} id="kt_careers_submit_button" 
												data-kt-indicator={mainSubmitButtonState?"on":"off"}
												disabled={mainSubmitButtonState}
												>APPLY</button>
											</div>
										</div>			
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

			<div className="modal fade" id="kt_modal_1" tabindex="-1" aria-hidden="true">
				<div className="modal-dialog mw-650px">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">Cancel Subscription</h5>
							<div className="modal-header pb-0 border-0 justify-content-end">
								<div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
									<span className="svg-icon svg-icon-1">
										<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
											<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
												<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
												<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
											</g>
										</svg>
									</span>
								</div>
							</div>
						</div>
						<div className="modal-body">
							<div className="form-group row">
								<div className="col-lg-12">
									Current subscription status is : {<span className="fs-5 fw-bolder text-success"> Active </span>}<br /><br /><br />
									<div className="mb-0 fv-row">
										<label className="d-flex align-items-center form-label mb-5">Select Cancel Mode
											<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title=""></i></label>
										<div className="mb-0">
											<label className="d-flex flex-stack mb-5 cursor-pointer">
												<span className="d-flex align-items-center me-2" for="once">
													<span className="d-flex flex-column">
														<span className="fw-bolder text-gray-800 text-hover-primary fs-5">Cancel now</span>
													</span>
												</span>
												<span className="form-check form-check-custom form-check-solid">
													<input className="form-check-input" id="cancel_radio" type="radio" name="cancel_radio" value="now" checked="checked" />
												</span>
											</label>
											<label className="d-flex flex-stack mb-5 cursor-pointer">
												<span className="d-flex align-items-center me-2" for="repeat">
													<span className="d-flex flex-column">
														<span className="fw-bolder text-gray-800 text-hover-primary fs-5">Cancel After Billing Period</span>
													</span>
												</span>
												<span className="form-check form-check-custom form-check-solid" >
													<input className="form-check-input" id="cancel_radio" type="radio" name="cancel_radio" value="billing_period" />
												</span>

											</label>
										</div>
									</div>
									<br /><br />
									<h3>Are you sure about cancelling subscription?</h3>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
							<form >
								<button type="button" className="btn btn-danger" onClick={(e) => { cancel_subscription(e); }} >Yes</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default AddAddon;