import React, { useState, useEffect, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { setCookie, getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js";
import { useTheme } from "./theme-provider.js"
import PageError from '../common_component/PageError.js';
import { useHistory } from 'react-router-dom';


export const PaymentWalletResponse = (props) => {

	var page_name = "payment-wallet-response";

	const { user, organization_id, user_role, organization_name, setPaymentResponseFlag } = useAuthData();
	const { theme, dropdownStyleDark } = useTheme();
	const history = useHistory()

	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	useEffect(() => {
		user !== null && user.email && updatePaymentStatus(props.match.params.response);
	}, [user])

	async function updatePaymentStatus(flag) {
		//console.log(props.match.params.response);
		var object = {};
		object.email = user.email;
		object.id = props.match.params.id;
		const api = flag == "success" ? "stripe-wallet-success" : "stripe-wallet-fail";
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/${api}`, { method: 'POST', body: JSON.stringify({ "email": user.email, "id": props.match.params.id, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		if (body.operation == "success") {
			setPaymentResponseFlag("success");
			history.push("/payment-wallet");
		}
		else {
			setPaymentResponseFlag("failure");
			history.push("/payment-wallet");
		}
	}

	return (
		<>
			<div className="d-flex flex-column flex-root" id="kt_app_root">
				<div className="d-flex flex-column flex-lg-row flex-column-fluid">
					<div className="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">
						<div className="flex-lg-row-fluid me-lg-15">
							<div className="card card-flush">
								{/* <div className="card-header">
									<div className="card-title">
										<h2 className="fw-bolder">Payment Response</h2>
									</div>
								</div> */}
								<div className="card-body pt-0">
									<div class="notice d-flex bg-light-warning rounded border-warning border border-dashed  p-6">
										<i class="ki-duotone ki-information fs-2tx text-warning me-4"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>

										<div class="d-flex flex-stack flex-grow-1 ">
											<div class=" fw-semibold">
												<h4 class="text-gray-900 fw-bold">Thank you for your wallet recharge!</h4>

												<div class="fs-6 text-gray-700 ">We are currently processing your recharge.  In the meantime, feel free to explore our website! <a class="fw-bold" href="https://adoerp.com">Explore Our Website</a>.</div>
											</div>

										</div>
									</div>
								</div>
							</div>
							<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
						</div>
					</div>
				</div>
			</div>

		</>);

};

export default PaymentWalletResponse;
