import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from "./theme-provider.js"
import copy from "copy-to-clipboard";
import { Drawer } from 'antd';
import { useAuthData } from "./auth-provider.js"
import { setCookie, getCookie } from '../common_component/cookie.js';

function Header(props) {
	const [image, setImage] = useState("/assets/media/avatars/blank.png");
	const [dashboardsPopup, setDashboardsPopup] = useState(false)
	const [organizationPanelPopup, setOrganizationPanelPopup] = useState(false)
	const [themePopup, setThemePopup] = useState(false)
	const [profilePopup, setProfilePopup] = useState(false)
	const [name, setname] = useState("");
	const { user } = useAuthData(page_name);
	const [matches, setMatches] = useState(window.matchMedia("(min-width: 767px)").matches)   //true: big screen, false: small screen
	const { theme, themeSelection, setThemeSelection } = useTheme();
	const [organizationList, setOrganizationList] = useState([]);
	const [organizationListApiState, setOrganizationListApiState] = useState(false);
	var page_name = "dashboard";

	useEffect(() => {
		window.matchMedia("(min-width: 768px)").addEventListener('change', e => setMatches(e.matches));
	}, []);

	useEffect(() => {
		if (getCookie("access_token") !== "") {
			async function getOrganizationDetails() {
				setOrganizationListApiState(true)
				const response = await fetch(
					`${process.env.REACT_APP_SERVER_URL}/show-organization`, { method: 'POST', body: JSON.stringify({ "page_name": "dashboard" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
				);
				const body = await response.json();

				if (body.length > 0) {
					setOrganizationListApiState(false)
					setOrganizationList(body)
				}
			}
			getOrganizationDetails();
		}
	}, [])


	useEffect(() => {
		async function getheaderdetails() {
			let currentUrl = window.location.href;
			let t = currentUrl.split("/");
			t = (/\d/.test(t[t.length - 1])) ? t[t.length - 2] : t[t.length - 1];
			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-header-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "organization_id": getCookie("organization_id"), "href": t }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();
			if (body.operation == "success") {
				if (body.data.account_details.hasOwnProperty("file_name")) {
					setname(body.data.account_details.account_name);
					if (body.data.account_details.file_name.length !== 0) {
						setImage(`${process.env.REACT_APP_GENERAL_IMAGE_BUCKET}/profile_image/${body.data.account_details.file_name}`)
					}
				}
				else {
					setname(user.name);
				}
			}
		}
		user !== null && user.email && getheaderdetails();
	}, [user])

	return (
		<>
			<div className="bg-body container-fluid d-flex align-items-stretch justify-content-between py-5" style={{ maxHeight: "10%", height: "10%" }}>
				<div className="d-flex align-items-center d-lg-none ms-n2 me-2">
					<div className="btn btn-icon btn-active-color-primary w-35px h-35px" onClick={() => { props.asideNavbarRef.current.focus(); props.setAsideNavbarExpandMobile(true); }}>
						<span className="svg-icon svg-icon-1">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="currentColor" />
								<path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="currentColor" />
							</svg>
						</span>
					</div>
				</div>

				<div className="app-container container-fluid d-flex align-items-stretch justify-content-between">
					{/* <div className="d-flex align-items-center d-lg-none ms-n2 me-2" title="Show sidebar menu">
						<div className="btn btn-icon btn-active-color-primary w-35px h-35px" id="kt_app_sidebar_mobile_toggle">
							<span className="svg-icon svg-icon-1">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="currentColor" />
									<path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="currentColor" />
								</svg>
							</span>

						</div>
					</div> */}

					<div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
						<a href="../../demo1/dist/index.html" className="d-lg-none">
							<img alt="Logo" src="https://cdn-adocrm.s3.amazonaws.com/static/ado-crm-logo.png" className="h-30px" />
						</a>

					</div>

					<div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1" id="kt_app_header_wrapper">
						{
							matches &&
							<div className="p-5 d-flex align-items-center position-relative" onMouseEnter={() => { setDashboardsPopup(true) }} onMouseLeave={() => { setDashboardsPopup(false) }} >
								<button className='btn btn-active-color-primary btn-active-light'>Quick Links</button>

								<div className="w-250px w-lg-700px"
									style={{
										padding: "1rem",
										zIndex: "105",
										position: "absolute",
										inset: "0px auto auto 0px",
										margin: "0px",
										transform: "translate(0px, 70.4px)",
										transition: "all 0.5s",
										borderRadius: "0.475rem",
										backgroundColor: "var(--kt-menu-dropdown-bg-color)",
										boxShadow: "var(--kt-menu-dropdown-box-shadow)",
										visibility: dashboardsPopup ? "visible" : "hidden",
										opacity: dashboardsPopup ? "1" : "0",
									}}
								>
									<div className="row">
										<div className="col-lg-6 mb-3">
											<div className="menu-item p-0 m-0 rounded bg-hover-secondary">
												<a href={`${process.env.REACT_APP_ACCOUNT_SERVER_URL}/add-item`} className="menu-link">
													<span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">

														<span className="svg-icon svg-icon-danger svg-icon-1">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M21 10H13V11C13 11.6 12.6 12 12 12C11.4 12 11 11.6 11 11V10H3C2.4 10 2 10.4 2 11V13H22V11C22 10.4 21.6 10 21 10Z" fill="currentColor" />
																<path opacity="0.3" d="M12 12C11.4 12 11 11.6 11 11V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V11C13 11.6 12.6 12 12 12Z" fill="currentColor" />
																<path opacity="0.3" d="M18.1 21H5.9C5.4 21 4.9 20.6 4.8 20.1L3 13H21L19.2 20.1C19.1 20.6 18.6 21 18.1 21ZM13 18V15C13 14.4 12.6 14 12 14C11.4 14 11 14.4 11 15V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18ZM17 18V15C17 14.4 16.6 14 16 14C15.4 14 15 14.4 15 15V18C15 18.6 15.4 19 16 19C16.6 19 17 18.6 17 18ZM9 18V15C9 14.4 8.6 14 8 14C7.4 14 7 14.4 7 15V18C7 18.6 7.4 19 8 19C8.6 19 9 18.6 9 18Z" fill="currentColor" />
															</svg>
														</span>

													</span>
													<span className="d-flex flex-column">
														<span className="fs-6 fw-bold text-gray-800">Add Item</span>
														<span className="fs-7 fw-semibold text-muted">Add Item</span>
													</span>
												</a>
											</div>
										</div>

										<div className="col-lg-6 mb-3">
											<div className="menu-item p-0 m-0 rounded bg-hover-secondary">
												<a href={`${process.env.REACT_APP_ACCOUNT_SERVER_URL}/add-customer`} className="menu-link">
													<span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
														<span className="svg-icon svg-icon-primary svg-icon-1">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z" fill="currentColor"></path>
																<rect opacity="0.3" x="8" y="3" width="8" height="8" rx="4" fill="currentColor"></rect>
															</svg>
														</span>

													</span>
													<span className="d-flex flex-column">
														<span className="fs-6 fw-bold text-gray-800">Add Customer</span>
														<span className="fs-7 fw-semibold text-muted">Add Customer</span>
													</span>
												</a>
											</div>
										</div>

										<div className="col-lg-6 mb-3">
											<div className="menu-item p-0 m-0 rounded bg-hover-secondary">
												<a href={`${process.env.REACT_APP_ACCOUNT_SERVER_URL}/create-purchase-order`} className="menu-link">
													<span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
														<i className="fa-solid fa-file-invoice text-primary fs-3 p-0"></i>
													</span>
													<span className="d-flex flex-column">
														<span className="fs-6 fw-bold text-gray-800">New Purchase Order</span>
														<span className="fs-7 fw-semibold text-muted">New Purchase Order</span>
													</span>
												</a>
											</div>
										</div>

										<div className="col-lg-6 mb-3">
											<div className="menu-item p-0 m-0 rounded bg-hover-secondary">
												<a href={`${process.env.REACT_APP_ACCOUNT_SERVER_URL}/create-goods-receive`} className="menu-link">
													<span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
														<i className="fa-solid fa-file-invoice text-primary fs-3 p-0"></i>
													</span>
													<span className="d-flex flex-column">
														<span className="fs-6 fw-bold text-gray-800">Create Goods Receive</span>
														<span className="fs-7 fw-semibold text-muted">Create Goods Receive</span>
													</span>
												</a>
											</div>
										</div>

										<div className="col-lg-6 mb-3">
											<div className="menu-item p-0 m-0 rounded bg-hover-secondary">
												<a href={`${process.env.REACT_APP_ACCOUNT_SERVER_URL}/create-bill`} className="menu-link">
													<span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
														<i className="fa-solid fa-money-bill-1-wave text-success fs-3 p-0"></i>
													</span>
													<span className="d-flex flex-column">
														<span className="fs-6 fw-bold text-gray-800">Create Bill</span>
														<span className="fs-7 fw-semibold text-muted">Create Bill</span>
													</span>
												</a>
											</div>
										</div>

										<div className="col-lg-6 mb-3">
											<div className="menu-item p-0 m-0 rounded bg-hover-secondary">
												<a href={`${process.env.REACT_APP_ACCOUNT_SERVER_URL}/create-customer-invoice`} className="menu-link">
													<span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
														<i className="fa-solid fa-file-invoice text-primary fs-3 p-0"></i>
													</span>
													<span className="d-flex flex-column">
														<span className="fs-6 fw-bold text-gray-800">Create Invoice</span>
														<span className="fs-7 fw-semibold text-muted">Create Invoice</span>
													</span>
												</a>
											</div>
										</div>

										<div className="col-lg-6 mb-3">
											<div className="menu-item p-0 m-0 rounded bg-hover-secondary">
												<a href={`${process.env.REACT_APP_ACCOUNT_SERVER_URL}/new-payment-received`} className="menu-link">
													<span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
														<i className="fa-solid fa-money-bill-1-wave fs-3 text-success p-0"></i>
													</span>
													<span className="d-flex flex-column">
														<span className="fs-6 fw-bold text-gray-800">New Payment Receive</span>
														<span className="fs-7 fw-semibold text-muted">New Payment Receive</span>
													</span>
												</a>
											</div>
										</div>

										<div className="col-lg-6 mb-3">
											<div className="menu-item p-0 m-0 rounded bg-hover-secondary">
												<a href={`${process.env.REACT_APP_ACCOUNT_SERVER_URL}/add-expense`} className="menu-link">
													<span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
														<i className="fa-solid fa-money-bill-1-wave text-success fs-3 p-0"></i>
													</span>
													<span className="d-flex flex-column">
														<span className="fs-6 fw-bold text-gray-800">New Expense</span>
														<span className="fs-7 fw-semibold text-muted">New Expense</span>
													</span>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						}

						<div className="app-navbar flex-shrink-0">
							{/* <div className="app-navbar-item ms-1 ms-lg-3">
								<button className="btn btn-icon btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px">
									<span className="svg-icon svg-icon-1">
									<i class="fa-solid fa-gear"></i>
									</span>
								</button>
							</div> */}
							<div className="app-navbar-item ms-1 ms-lg-3">
								<button className="btn btn-icon btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px" onClick={() => { setOrganizationPanelPopup(true) }}>
									<span className="svg-icon svg-icon-1">
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<rect x="8" y="9" width="3" height="10" rx="1.5" fill="currentColor" />
											<rect opacity="0.5" x="13" y="5" width="3" height="14" rx="1.5" fill="currentColor" />
											<rect x="18" y="11" width="3" height="8" rx="1.5" fill="currentColor" />
											<rect x="3" y="13" width="3" height="6" rx="1.5" fill="currentColor" />
										</svg>
									</span>
								</button>
							</div>

							<div className="app-navbar-item ms-1 ms-lg-3 position-relative" onMouseEnter={() => { setThemePopup(true) }} onMouseLeave={() => { setThemePopup(false) }}>
								<button className="btn btn-icon btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px" >
									{
										theme === "light" ?
											<span className="svg-icon theme-light-show svg-icon-2">
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M11.9905 5.62598C10.7293 5.62574 9.49646 5.9995 8.44775 6.69997C7.39903 7.40045 6.58159 8.39619 6.09881 9.56126C5.61603 10.7263 5.48958 12.0084 5.73547 13.2453C5.98135 14.4823 6.58852 15.6185 7.48019 16.5104C8.37186 17.4022 9.50798 18.0096 10.7449 18.2557C11.9818 18.5019 13.2639 18.3757 14.429 17.8931C15.5942 17.4106 16.5901 16.5933 17.2908 15.5448C17.9915 14.4962 18.3655 13.2634 18.3655 12.0023C18.3637 10.3119 17.6916 8.69129 16.4964 7.49593C15.3013 6.30056 13.6808 5.62806 11.9905 5.62598Z" fill="currentColor" />
													<path d="M22.1258 10.8771H20.627C20.3286 10.8771 20.0424 10.9956 19.8314 11.2066C19.6204 11.4176 19.5018 11.7038 19.5018 12.0023C19.5018 12.3007 19.6204 12.5869 19.8314 12.7979C20.0424 13.0089 20.3286 13.1274 20.627 13.1274H22.1258C22.4242 13.1274 22.7104 13.0089 22.9214 12.7979C23.1324 12.5869 23.2509 12.3007 23.2509 12.0023C23.2509 11.7038 23.1324 11.4176 22.9214 11.2066C22.7104 10.9956 22.4242 10.8771 22.1258 10.8771Z" fill="currentColor" />
													<path d="M11.9905 19.4995C11.6923 19.5 11.4064 19.6187 11.1956 19.8296C10.9848 20.0405 10.8663 20.3265 10.866 20.6247V22.1249C10.866 22.4231 10.9845 22.7091 11.1953 22.9199C11.4062 23.1308 11.6922 23.2492 11.9904 23.2492C12.2886 23.2492 12.5746 23.1308 12.7854 22.9199C12.9963 22.7091 13.1147 22.4231 13.1147 22.1249V20.6247C13.1145 20.3265 12.996 20.0406 12.7853 19.8296C12.5745 19.6187 12.2887 19.5 11.9905 19.4995Z" fill="currentColor" />
													<path d="M4.49743 12.0023C4.49718 11.704 4.37865 11.4181 4.16785 11.2072C3.95705 10.9962 3.67119 10.8775 3.37298 10.8771H1.87445C1.57603 10.8771 1.28984 10.9956 1.07883 11.2066C0.867812 11.4176 0.749266 11.7038 0.749266 12.0023C0.749266 12.3007 0.867812 12.5869 1.07883 12.7979C1.28984 13.0089 1.57603 13.1274 1.87445 13.1274H3.37299C3.6712 13.127 3.95706 13.0083 4.16785 12.7973C4.37865 12.5864 4.49718 12.3005 4.49743 12.0023Z" fill="currentColor" />
													<path d="M11.9905 4.50058C12.2887 4.50012 12.5745 4.38141 12.7853 4.17048C12.9961 3.95954 13.1147 3.67361 13.1149 3.3754V1.87521C13.1149 1.57701 12.9965 1.29103 12.7856 1.08017C12.5748 0.869313 12.2888 0.750854 11.9906 0.750854C11.6924 0.750854 11.4064 0.869313 11.1955 1.08017C10.9847 1.29103 10.8662 1.57701 10.8662 1.87521V3.3754C10.8664 3.67359 10.9849 3.95952 11.1957 4.17046C11.4065 4.3814 11.6923 4.50012 11.9905 4.50058Z" fill="currentColor" />
													<path d="M18.8857 6.6972L19.9465 5.63642C20.0512 5.53209 20.1343 5.40813 20.1911 5.27163C20.2479 5.13513 20.2772 4.98877 20.2774 4.84093C20.2775 4.69309 20.2485 4.54667 20.192 4.41006C20.1355 4.27344 20.0526 4.14932 19.948 4.04478C19.8435 3.94024 19.7194 3.85734 19.5828 3.80083C19.4462 3.74432 19.2997 3.71531 19.1519 3.71545C19.0041 3.7156 18.8577 3.7449 18.7212 3.80167C18.5847 3.85845 18.4607 3.94159 18.3564 4.04633L17.2956 5.10714C17.1909 5.21147 17.1077 5.33543 17.0509 5.47194C16.9942 5.60844 16.9649 5.7548 16.9647 5.90264C16.9646 6.05048 16.9936 6.19689 17.0501 6.33351C17.1066 6.47012 17.1895 6.59425 17.294 6.69878C17.3986 6.80332 17.5227 6.88621 17.6593 6.94272C17.7959 6.99923 17.9424 7.02824 18.0902 7.02809C18.238 7.02795 18.3844 6.99865 18.5209 6.94187C18.6574 6.88509 18.7814 6.80195 18.8857 6.6972Z" fill="currentColor" />
													<path d="M18.8855 17.3073C18.7812 17.2026 18.6572 17.1195 18.5207 17.0627C18.3843 17.006 18.2379 16.9767 18.0901 16.9766C17.9423 16.9764 17.7959 17.0055 17.6593 17.062C17.5227 17.1185 17.3986 17.2014 17.2941 17.3059C17.1895 17.4104 17.1067 17.5345 17.0501 17.6711C16.9936 17.8077 16.9646 17.9541 16.9648 18.1019C16.9649 18.2497 16.9942 18.3961 17.0509 18.5326C17.1077 18.6691 17.1908 18.793 17.2955 18.8974L18.3563 19.9582C18.4606 20.0629 18.5846 20.146 18.721 20.2027C18.8575 20.2595 19.0039 20.2887 19.1517 20.2889C19.2995 20.289 19.4459 20.26 19.5825 20.2035C19.7191 20.147 19.8432 20.0641 19.9477 19.9595C20.0523 19.855 20.1351 19.7309 20.1916 19.5943C20.2482 19.4577 20.2772 19.3113 20.277 19.1635C20.2769 19.0157 20.2476 18.8694 20.1909 18.7329C20.1341 18.5964 20.051 18.4724 19.9463 18.3681L18.8855 17.3073Z" fill="currentColor" />
													<path d="M5.09528 17.3072L4.0345 18.368C3.92972 18.4723 3.84655 18.5963 3.78974 18.7328C3.73294 18.8693 3.70362 19.0156 3.70346 19.1635C3.7033 19.3114 3.7323 19.4578 3.78881 19.5944C3.84532 19.7311 3.92822 19.8552 4.03277 19.9598C4.13732 20.0643 4.26147 20.1472 4.3981 20.2037C4.53473 20.2602 4.68117 20.2892 4.82902 20.2891C4.97688 20.2889 5.12325 20.2596 5.25976 20.2028C5.39627 20.146 5.52024 20.0628 5.62456 19.958L6.68536 18.8973C6.79007 18.7929 6.87318 18.6689 6.92993 18.5325C6.98667 18.396 7.01595 18.2496 7.01608 18.1018C7.01621 17.954 6.98719 17.8076 6.93068 17.671C6.87417 17.5344 6.79129 17.4103 6.68676 17.3058C6.58224 17.2012 6.45813 17.1183 6.32153 17.0618C6.18494 17.0053 6.03855 16.9763 5.89073 16.9764C5.74291 16.9766 5.59657 17.0058 5.46007 17.0626C5.32358 17.1193 5.19962 17.2024 5.09528 17.3072Z" fill="currentColor" />
													<path d="M5.09541 6.69715C5.19979 6.8017 5.32374 6.88466 5.4602 6.94128C5.59665 6.9979 5.74292 7.02708 5.89065 7.02714C6.03839 7.0272 6.18469 6.99815 6.32119 6.94164C6.45769 6.88514 6.58171 6.80228 6.68618 6.69782C6.79064 6.59336 6.87349 6.46933 6.93 6.33283C6.9865 6.19633 7.01556 6.05003 7.01549 5.9023C7.01543 5.75457 6.98625 5.60829 6.92963 5.47184C6.87301 5.33539 6.79005 5.21143 6.6855 5.10706L5.6247 4.04626C5.5204 3.94137 5.39643 3.8581 5.25989 3.80121C5.12335 3.74432 4.97692 3.71493 4.82901 3.71472C4.68109 3.71452 4.53458 3.7435 4.39789 3.80001C4.26119 3.85652 4.13699 3.93945 4.03239 4.04404C3.9278 4.14864 3.84487 4.27284 3.78836 4.40954C3.73185 4.54624 3.70287 4.69274 3.70308 4.84066C3.70329 4.98858 3.73268 5.135 3.78957 5.27154C3.84646 5.40808 3.92974 5.53205 4.03462 5.63635L5.09541 6.69715Z" fill="currentColor" />
												</svg>
											</span> :
											<span className="svg-icon theme-dark-show svg-icon-2">
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M19.0647 5.43757C19.3421 5.43757 19.567 5.21271 19.567 4.93534C19.567 4.65796 19.3421 4.43311 19.0647 4.43311C18.7874 4.43311 18.5625 4.65796 18.5625 4.93534C18.5625 5.21271 18.7874 5.43757 19.0647 5.43757Z" fill="currentColor" />
													<path d="M20.0692 9.48884C20.3466 9.48884 20.5714 9.26398 20.5714 8.98661C20.5714 8.70923 20.3466 8.48438 20.0692 8.48438C19.7918 8.48438 19.567 8.70923 19.567 8.98661C19.567 9.26398 19.7918 9.48884 20.0692 9.48884Z" fill="currentColor" />
													<path d="M12.0335 20.5714C15.6943 20.5714 18.9426 18.2053 20.1168 14.7338C20.1884 14.5225 20.1114 14.289 19.9284 14.161C19.746 14.034 19.5003 14.0418 19.3257 14.1821C18.2432 15.0546 16.9371 15.5156 15.5491 15.5156C12.2257 15.5156 9.48884 12.8122 9.48884 9.48886C9.48884 7.41079 10.5773 5.47137 12.3449 4.35752C12.5342 4.23832 12.6 4.00733 12.5377 3.79251C12.4759 3.57768 12.2571 3.42859 12.0335 3.42859C7.32556 3.42859 3.42857 7.29209 3.42857 12C3.42857 16.7079 7.32556 20.5714 12.0335 20.5714Z" fill="currentColor" />
													<path d="M13.0379 7.47998C13.8688 7.47998 14.5446 8.15585 14.5446 8.98668C14.5446 9.26428 14.7693 9.48891 15.0469 9.48891C15.3245 9.48891 15.5491 9.26428 15.5491 8.98668C15.5491 8.15585 16.225 7.47998 17.0558 7.47998C17.3334 7.47998 17.558 7.25535 17.558 6.97775C17.558 6.70015 17.3334 6.47552 17.0558 6.47552C16.225 6.47552 15.5491 5.76616 15.5491 4.93534C15.5491 4.65774 15.3245 4.43311 15.0469 4.43311C14.7693 4.43311 14.5446 4.65774 14.5446 4.93534C14.5446 5.76616 13.8688 6.47552 13.0379 6.47552C12.7603 6.47552 12.5357 6.70015 12.5357 6.97775C12.5357 7.25535 12.7603 7.47998 13.0379 7.47998Z" fill="currentColor" />
												</svg>
											</span>
									}
								</button>

								<div className="menu-title-gray-700 py-4 w-175px"
									style={{
										zIndex: "105",
										position: "absolute",
										inset: "0px 0px auto auto",
										margin: "0px",
										transform: "translate(0px, 70.4px)",
										transition: "all 0.5s",
										borderRadius: "0.475rem",
										backgroundColor: "var(--kt-menu-dropdown-bg-color)",
										boxShadow: "var(--kt-menu-dropdown-box-shadow)",
										visibility: themePopup ? "visible" : "hidden",
										opacity: themePopup ? "1" : "0",
									}}
								>
									<div className="menu-item px-3 my-0" onClick={() => { setThemeSelection("light") }}>
										<span className={'menu-link px-3 py-2 bg-hover-secondary text-hover-primary rounded ' + (themeSelection === "light" ? "bg-primary text-white" : "")}>
											<span className="menu-icon">

												<span className="svg-icon svg-icon-3">
													<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M11.9905 5.62598C10.7293 5.62574 9.49646 5.9995 8.44775 6.69997C7.39903 7.40045 6.58159 8.39619 6.09881 9.56126C5.61603 10.7263 5.48958 12.0084 5.73547 13.2453C5.98135 14.4823 6.58852 15.6185 7.48019 16.5104C8.37186 17.4022 9.50798 18.0096 10.7449 18.2557C11.9818 18.5019 13.2639 18.3757 14.429 17.8931C15.5942 17.4106 16.5901 16.5933 17.2908 15.5448C17.9915 14.4962 18.3655 13.2634 18.3655 12.0023C18.3637 10.3119 17.6916 8.69129 16.4964 7.49593C15.3013 6.30056 13.6808 5.62806 11.9905 5.62598Z" fill="currentColor" />
														<path d="M22.1258 10.8771H20.627C20.3286 10.8771 20.0424 10.9956 19.8314 11.2066C19.6204 11.4176 19.5018 11.7038 19.5018 12.0023C19.5018 12.3007 19.6204 12.5869 19.8314 12.7979C20.0424 13.0089 20.3286 13.1274 20.627 13.1274H22.1258C22.4242 13.1274 22.7104 13.0089 22.9214 12.7979C23.1324 12.5869 23.2509 12.3007 23.2509 12.0023C23.2509 11.7038 23.1324 11.4176 22.9214 11.2066C22.7104 10.9956 22.4242 10.8771 22.1258 10.8771Z" fill="currentColor" />
														<path d="M11.9905 19.4995C11.6923 19.5 11.4064 19.6187 11.1956 19.8296C10.9848 20.0405 10.8663 20.3265 10.866 20.6247V22.1249C10.866 22.4231 10.9845 22.7091 11.1953 22.9199C11.4062 23.1308 11.6922 23.2492 11.9904 23.2492C12.2886 23.2492 12.5746 23.1308 12.7854 22.9199C12.9963 22.7091 13.1147 22.4231 13.1147 22.1249V20.6247C13.1145 20.3265 12.996 20.0406 12.7853 19.8296C12.5745 19.6187 12.2887 19.5 11.9905 19.4995Z" fill="currentColor" />
														<path d="M4.49743 12.0023C4.49718 11.704 4.37865 11.4181 4.16785 11.2072C3.95705 10.9962 3.67119 10.8775 3.37298 10.8771H1.87445C1.57603 10.8771 1.28984 10.9956 1.07883 11.2066C0.867812 11.4176 0.749266 11.7038 0.749266 12.0023C0.749266 12.3007 0.867812 12.5869 1.07883 12.7979C1.28984 13.0089 1.57603 13.1274 1.87445 13.1274H3.37299C3.6712 13.127 3.95706 13.0083 4.16785 12.7973C4.37865 12.5864 4.49718 12.3005 4.49743 12.0023Z" fill="currentColor" />
														<path d="M11.9905 4.50058C12.2887 4.50012 12.5745 4.38141 12.7853 4.17048C12.9961 3.95954 13.1147 3.67361 13.1149 3.3754V1.87521C13.1149 1.57701 12.9965 1.29103 12.7856 1.08017C12.5748 0.869313 12.2888 0.750854 11.9906 0.750854C11.6924 0.750854 11.4064 0.869313 11.1955 1.08017C10.9847 1.29103 10.8662 1.57701 10.8662 1.87521V3.3754C10.8664 3.67359 10.9849 3.95952 11.1957 4.17046C11.4065 4.3814 11.6923 4.50012 11.9905 4.50058Z" fill="currentColor" />
														<path d="M18.8857 6.6972L19.9465 5.63642C20.0512 5.53209 20.1343 5.40813 20.1911 5.27163C20.2479 5.13513 20.2772 4.98877 20.2774 4.84093C20.2775 4.69309 20.2485 4.54667 20.192 4.41006C20.1355 4.27344 20.0526 4.14932 19.948 4.04478C19.8435 3.94024 19.7194 3.85734 19.5828 3.80083C19.4462 3.74432 19.2997 3.71531 19.1519 3.71545C19.0041 3.7156 18.8577 3.7449 18.7212 3.80167C18.5847 3.85845 18.4607 3.94159 18.3564 4.04633L17.2956 5.10714C17.1909 5.21147 17.1077 5.33543 17.0509 5.47194C16.9942 5.60844 16.9649 5.7548 16.9647 5.90264C16.9646 6.05048 16.9936 6.19689 17.0501 6.33351C17.1066 6.47012 17.1895 6.59425 17.294 6.69878C17.3986 6.80332 17.5227 6.88621 17.6593 6.94272C17.7959 6.99923 17.9424 7.02824 18.0902 7.02809C18.238 7.02795 18.3844 6.99865 18.5209 6.94187C18.6574 6.88509 18.7814 6.80195 18.8857 6.6972Z" fill="currentColor" />
														<path d="M18.8855 17.3073C18.7812 17.2026 18.6572 17.1195 18.5207 17.0627C18.3843 17.006 18.2379 16.9767 18.0901 16.9766C17.9423 16.9764 17.7959 17.0055 17.6593 17.062C17.5227 17.1185 17.3986 17.2014 17.2941 17.3059C17.1895 17.4104 17.1067 17.5345 17.0501 17.6711C16.9936 17.8077 16.9646 17.9541 16.9648 18.1019C16.9649 18.2497 16.9942 18.3961 17.0509 18.5326C17.1077 18.6691 17.1908 18.793 17.2955 18.8974L18.3563 19.9582C18.4606 20.0629 18.5846 20.146 18.721 20.2027C18.8575 20.2595 19.0039 20.2887 19.1517 20.2889C19.2995 20.289 19.4459 20.26 19.5825 20.2035C19.7191 20.147 19.8432 20.0641 19.9477 19.9595C20.0523 19.855 20.1351 19.7309 20.1916 19.5943C20.2482 19.4577 20.2772 19.3113 20.277 19.1635C20.2769 19.0157 20.2476 18.8694 20.1909 18.7329C20.1341 18.5964 20.051 18.4724 19.9463 18.3681L18.8855 17.3073Z" fill="currentColor" />
														<path d="M5.09528 17.3072L4.0345 18.368C3.92972 18.4723 3.84655 18.5963 3.78974 18.7328C3.73294 18.8693 3.70362 19.0156 3.70346 19.1635C3.7033 19.3114 3.7323 19.4578 3.78881 19.5944C3.84532 19.7311 3.92822 19.8552 4.03277 19.9598C4.13732 20.0643 4.26147 20.1472 4.3981 20.2037C4.53473 20.2602 4.68117 20.2892 4.82902 20.2891C4.97688 20.2889 5.12325 20.2596 5.25976 20.2028C5.39627 20.146 5.52024 20.0628 5.62456 19.958L6.68536 18.8973C6.79007 18.7929 6.87318 18.6689 6.92993 18.5325C6.98667 18.396 7.01595 18.2496 7.01608 18.1018C7.01621 17.954 6.98719 17.8076 6.93068 17.671C6.87417 17.5344 6.79129 17.4103 6.68676 17.3058C6.58224 17.2012 6.45813 17.1183 6.32153 17.0618C6.18494 17.0053 6.03855 16.9763 5.89073 16.9764C5.74291 16.9766 5.59657 17.0058 5.46007 17.0626C5.32358 17.1193 5.19962 17.2024 5.09528 17.3072Z" fill="currentColor" />
														<path d="M5.09541 6.69715C5.19979 6.8017 5.32374 6.88466 5.4602 6.94128C5.59665 6.9979 5.74292 7.02708 5.89065 7.02714C6.03839 7.0272 6.18469 6.99815 6.32119 6.94164C6.45769 6.88514 6.58171 6.80228 6.68618 6.69782C6.79064 6.59336 6.87349 6.46933 6.93 6.33283C6.9865 6.19633 7.01556 6.05003 7.01549 5.9023C7.01543 5.75457 6.98625 5.60829 6.92963 5.47184C6.87301 5.33539 6.79005 5.21143 6.6855 5.10706L5.6247 4.04626C5.5204 3.94137 5.39643 3.8581 5.25989 3.80121C5.12335 3.74432 4.97692 3.71493 4.82901 3.71472C4.68109 3.71452 4.53458 3.7435 4.39789 3.80001C4.26119 3.85652 4.13699 3.93945 4.03239 4.04404C3.9278 4.14864 3.84487 4.27284 3.78836 4.40954C3.73185 4.54624 3.70287 4.69274 3.70308 4.84066C3.70329 4.98858 3.73268 5.135 3.78957 5.27154C3.84646 5.40808 3.92974 5.53205 4.03462 5.63635L5.09541 6.69715Z" fill="currentColor" />
													</svg>
												</span>

											</span>
											<span className="">Light</span>
										</span>
									</div>
									<div className="menu-item px-3 my-0" onClick={() => { setThemeSelection("dark") }}>
										<span className={'menu-link px-3 py-2 bg-hover-secondary text-hover-primary rounded ' + (themeSelection === "dark" ? "bg-primary text-white" : "")}>
											<span className="menu-icon">

												<span className="svg-icon svg-icon-3">
													<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M19.0647 5.43757C19.3421 5.43757 19.567 5.21271 19.567 4.93534C19.567 4.65796 19.3421 4.43311 19.0647 4.43311C18.7874 4.43311 18.5625 4.65796 18.5625 4.93534C18.5625 5.21271 18.7874 5.43757 19.0647 5.43757Z" fill="currentColor" />
														<path d="M20.0692 9.48884C20.3466 9.48884 20.5714 9.26398 20.5714 8.98661C20.5714 8.70923 20.3466 8.48438 20.0692 8.48438C19.7918 8.48438 19.567 8.70923 19.567 8.98661C19.567 9.26398 19.7918 9.48884 20.0692 9.48884Z" fill="currentColor" />
														<path d="M12.0335 20.5714C15.6943 20.5714 18.9426 18.2053 20.1168 14.7338C20.1884 14.5225 20.1114 14.289 19.9284 14.161C19.746 14.034 19.5003 14.0418 19.3257 14.1821C18.2432 15.0546 16.9371 15.5156 15.5491 15.5156C12.2257 15.5156 9.48884 12.8122 9.48884 9.48886C9.48884 7.41079 10.5773 5.47137 12.3449 4.35752C12.5342 4.23832 12.6 4.00733 12.5377 3.79251C12.4759 3.57768 12.2571 3.42859 12.0335 3.42859C7.32556 3.42859 3.42857 7.29209 3.42857 12C3.42857 16.7079 7.32556 20.5714 12.0335 20.5714Z" fill="currentColor" />
														<path d="M13.0379 7.47998C13.8688 7.47998 14.5446 8.15585 14.5446 8.98668C14.5446 9.26428 14.7693 9.48891 15.0469 9.48891C15.3245 9.48891 15.5491 9.26428 15.5491 8.98668C15.5491 8.15585 16.225 7.47998 17.0558 7.47998C17.3334 7.47998 17.558 7.25535 17.558 6.97775C17.558 6.70015 17.3334 6.47552 17.0558 6.47552C16.225 6.47552 15.5491 5.76616 15.5491 4.93534C15.5491 4.65774 15.3245 4.43311 15.0469 4.43311C14.7693 4.43311 14.5446 4.65774 14.5446 4.93534C14.5446 5.76616 13.8688 6.47552 13.0379 6.47552C12.7603 6.47552 12.5357 6.70015 12.5357 6.97775C12.5357 7.25535 12.7603 7.47998 13.0379 7.47998Z" fill="currentColor" />
													</svg>
												</span>

											</span>
											<span className="">Dark</span>
										</span>
									</div>
									<div className="menu-item px-3 my-0" onClick={() => { setThemeSelection("system") }}>
										<span className={'menu-link px-3 py-2 bg-hover-secondary text-hover-primary rounded ' + (themeSelection === "system" ? "bg-primary text-white" : "")}>
											<span className="menu-icon" >

												<span className="svg-icon svg-icon-3">
													<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path fillRule="evenodd" clipRule="evenodd" d="M1.34375 3.9463V15.2178C1.34375 16.119 2.08105 16.8563 2.98219 16.8563H8.65093V19.4594H6.15702C5.38853 19.4594 4.75981 19.9617 4.75981 20.5757V21.6921H19.2403V20.5757C19.2403 19.9617 18.6116 19.4594 17.8431 19.4594H15.3492V16.8563H21.0179C21.919 16.8563 22.6562 16.119 22.6562 15.2178V3.9463C22.6562 3.04516 21.9189 2.30786 21.0179 2.30786H2.98219C2.08105 2.30786 1.34375 3.04516 1.34375 3.9463ZM12.9034 9.9016C13.241 9.98792 13.5597 10.1216 13.852 10.2949L15.0393 9.4353L15.9893 10.3853L15.1297 11.5727C15.303 11.865 15.4366 12.1837 15.523 12.5212L16.97 12.7528V13.4089H13.9851C13.9766 12.3198 13.0912 11.4394 12 11.4394C10.9089 11.4394 10.0235 12.3198 10.015 13.4089H7.03006V12.7528L8.47712 12.5211C8.56345 12.1836 8.69703 11.8649 8.87037 11.5727L8.0107 10.3853L8.96078 9.4353L10.148 10.2949C10.4404 10.1215 10.759 9.98788 11.0966 9.9016L11.3282 8.45467H12.6718L12.9034 9.9016ZM16.1353 7.93758C15.6779 7.93758 15.3071 7.56681 15.3071 7.1094C15.3071 6.652 15.6779 6.28122 16.1353 6.28122C16.5926 6.28122 16.9634 6.652 16.9634 7.1094C16.9634 7.56681 16.5926 7.93758 16.1353 7.93758ZM2.71385 14.0964V3.90518C2.71385 3.78023 2.81612 3.67796 2.94107 3.67796H21.0589C21.1839 3.67796 21.2861 3.78023 21.2861 3.90518V14.0964C15.0954 14.0964 8.90462 14.0964 2.71385 14.0964Z" fill="currentColor" />
													</svg>
												</span>

											</span>
											<span className="">System</span>
										</span>
									</div>
								</div>
							</div>

							<button className="app-navbar-item ms-1 ms-lg-3 btn p-0 position-relative" onClick={() => { setProfilePopup(true) }} onBlur={() => { setProfilePopup(false) }}>
								<div className="cursor-pointer symbol symbol-35px symbol-md-40px"><img src={image} alt="user" /></div>
								<div className="fs-6 menu-gray-800 py-4 w-275px"
									style={{
										zIndex: "105",
										position: "absolute",
										inset: "0px 0px auto auto",
										margin: "0px",
										transform: "translate(0px, 70.4px)",
										transition: "all 0.5s",
										borderRadius: "0.475rem",
										backgroundColor: "var(--kt-menu-dropdown-bg-color)",
										boxShadow: "var(--kt-menu-dropdown-box-shadow)",
										visibility: profilePopup ? "visible" : "hidden",
										opacity: profilePopup ? "1" : "0",
									}}
								>
									<div className="menu-item px-3">
										<div className="menu-content d-flex align-items-center px-3">

											<div className="symbol symbol-50px me-5"><img src={image} alt="user" /></div>
											<div className="d-flex flex-column">
												<div className="fw-bold d-flex align-items-center fs-5">{name}
													<span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">Pro</span></div>
												{user !== null &&
													<span className="text-hover-primary fw-semibold text-muted text-hover-primary fs-7">{user.email}</span>}
											</div>

										</div>
									</div>

									{/* <div className="separator my-2"></div>
								<div className="menu-item px-5">
									<Link to="/account-overview" className="menu-link px-5 bg-hover-secondary text-hover-primary rounded">My Profile</Link>
								</div>
								<div className="menu-item px-5">
									<Link to="/item-list" className="menu-link px-5 bg-hover-secondary text-hover-primary rounded">Items</Link>
								</div>
								<div className="menu-item px-5">
									<Link to="/dealer-collection-list" className="menu-link px-5 bg-hover-secondary text-hover-primary rounded">Collections</Link>
								</div>
								<div className="menu-item px-5">
									<Link to="/dealer-order-list" className="menu-link px-5 bg-hover-secondary text-hover-primary rounded">Dealer Orders</Link>
								</div>
								<div className="separator my-2"></div> */}

									<div className="menu-item px-5">
										{/* <span className="menu-link px-5 bg-hover-secondary rounded">
										<span className="menu-title position-relative">Language
											<span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">English
												<img className="w-15px h-15px rounded-1 ms-2" src="/assets/media/flags/united-states.svg" alt='' />
											</span>
										</span>
									</span> */}

										{/* <div className="menu-sub menu-sub-dropdown w-175px py-4">
											<div className="menu-item px-3">
												<span className="symbol symbol-20px me-4"><img className="rounded-1" src="/assets/media/flags/united-states.svg" alt='' /></span>English
											</div>
										</div> */}
									</div>
									{/* <div className="menu-item px-5 my-1">
									<Link to="/organization-profile" className="menu-link px-5 bg-hover-secondary text-hover-primary rounded">Organization Details</Link>
								</div> */}
									<div className="menu-item px-5">
										<span onClick={() => {
											setCookie("access_token", "", -1, true);
											setCookie("organization_id", "", -1, true);
											if (getCookie("access_token") === "" && getCookie("organization_id") === "") {
												window.location.href = "/login";
											}
										}} className="menu-link px-5 bg-hover-secondary text-hover-primary rounded">Sign Out</span>
									</div>
								</div>
							</button>
						</div>

					</div>

				</div>

			</div>
			<Drawer width={window.innerWidth > 767 ? 500 : (window.innerWidth - 75)} className="bg-body" styles={{ body: { padding: "1.5rem" } }} onClose={() => { setOrganizationPanelPopup(false) }} open={organizationPanelPopup}
				title={
					<span className='text-dark ms-5'>My Organizations</span>
				}
				closeIcon={
					<span>
						<svg viewBox="0 0 48 48" width="38px" height="38px">
							<path fill="#f44336" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z" />
							<path fill="#fff" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z" />
							<path fill="#fff" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z" />
						</svg>
					</span>
				}
				footer={
					<div className="p-5">
						<Link to="/show-organizations" className="text-primary" onClick={() => { setOrganizationPanelPopup(false) }} >Manage All Organizations <i className="bi bi-caret-right-fill"></i></Link>
					</div>
				}
			>
				{
					organizationListApiState ? <div class="py-20 text-center"><div class="spinner-border" style={{ width: "5rem", height: "5rem" }}></div></div> :
						organizationList.length > 0 && organizationList.map((obj, index) => {
							return (
								<div key={index} className={`align-items-center border border-dashed d-flex my-5 mx-auto p-7 rounded cursor-pointer ${getCookie("organization_id") === obj.organization_id ? "border-primary bg-light-primary" : "border-secondary bg-light-secondary"}`} style={{ width: "fit-content" }} onClick={() => { setCookie('organization_id', obj.organization_id, 1, true); window.location.reload(); }}>
									<span className="svg-icon svg-icon-3x me-5">
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="black"></path>
											<path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="black"></path>
										</svg>
									</span>
									<span className="d-block fw-bold text-start">
										<span className="text-dark fw-bolder d-block fs-4 mb-2">{obj.organization_name}</span>
										<div className="d-flex align-items-center mb-2 text-nowrap">
											<span className="text-muted fw-semibold fs-6">Organization ID:</span><span className="badge badge-light-info fs-3 me-4">{obj.organization_id}</span>
											<button onClick={(e) => { copy(obj.organization_id); e.stopPropagation(); }} className="btn btn-primary btn-icon btn-sm btn-outline-light">
												<span className="svg-icon svg-icon-2">
													<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
														<path opacity="0.5" d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z" fill="currentColor"></path>
														<path fillRule="evenodd" clipRule="evenodd" d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z" fill="currentColor"></path>
													</svg>
												</span>
											</button>
										</div>
									</span>
								</div>
							)
						})
				}
			</Drawer>
		</>
	);
}
export default Header;