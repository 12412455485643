import React from "react"
import BasePage from "./BasePage";
import { BrowserRouter } from 'react-router-dom';
import "./App.css"

import { ThemeProvider, useTheme } from "./includes/theme-provider"
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles'

function App() {

    return (
        <ThemeProvider defaultTheme="system" storageKey="kt_theme_mode_value">
            <MuiWrapper>
                <BrowserRouter>
                    <BasePage />
                </BrowserRouter>
            </MuiWrapper>
        </ThemeProvider>
    );
}

function MuiWrapper({ children }) {
    const { theme } = useTheme()

    return (
        <>
            <MuiThemeProvider theme={createTheme({ palette: { mode: theme } })}>
                {children}
            </MuiThemeProvider>
        </>
    );
}

export default App;
