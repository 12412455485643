import React, {useState} from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';
import {getCookie} from './cookie.js';

const renderTime = ({ remainingTime }) => {
	if (remainingTime === 1) {
		window.location.href = "/login";
		return <div className="timer">Too late...</div>;
	}

	return (
		<div className="timer">
			<div className="text">Remaining</div>
			<div className="value">{remainingTime}</div>
			<div className="text">seconds</div>
		</div>
	);
};

export default function Timer_modal(props){
	const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)

	return (
		<Modal show={props.clockTimerModalShow} onHide={()=>{props.setClockTimerModalShow1()}} size="lg" centered>
			<Modal.Header>
				<Modal.Title>Session Out</Modal.Title>
				<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={()=>{props.setClockTimerModalShow1(false)}}>
					<span className="svg-icon svg-icon-1">
						<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
							<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
								<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
								<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
							</g>
						</svg>
					</span>
				</div>
			</Modal.Header>
			<Modal.Body>
				<div className='container'>
					<div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

						<div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">
							<span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
								<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
									<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
									<rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
									<rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
								</svg>
							</span>

							<div className="d-flex flex-stack flex-grow-1">
								<div className="fw-bold">
									<h4 className="text-gray-800 fw-bolder">Warning</h4>
									<div className="fs-6 text-gray-600">Your session is going to time out</div>
								</div>
							</div>
						</div>

						{
							props.clock_timer > 0 &&
							<div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
								<CountdownCircleTimer
									isPlaying
									duration={props.clock_timer}
									colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
									onComplete={(e) => {console.log(e); return [true, 1000]}}
								>
									{renderTime}
								</CountdownCircleTimer>
							</div>
						}
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer> 
				<button onClick={(e) => {
					let object={}
					object.token = getCookie("access_token")
					object.organization_id = props.organization_id

					let headers = {
						headers: {
							"Content-Type": "application/json",
							"Accept": "*/*"
						}
					}
					setMainSubmitButtonState(true);
					axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {
						setMainSubmitButtonState(false);
						if(props.setTimer == undefined){
							props.timer_ref.current = res.data.audience_count
						}
						else{
							props.setTimer(res.data.audience_count)
						}
						props.setClockTimerModalShow1(false)
					})
				}} className="btn btn-primary" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}>
					<span className="indicator-label">Stay Signed in</span>
					<span className="indicator-progress">Please wait...
						<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
					</span>
				</button>
			</Modal.Footer>
		</Modal>
	);
};
