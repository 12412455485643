import React from 'react'

const PageError = ({ children, message="Some error occured" }) => {

    return (
        <div className="card card-flush my-5 shadow-sm border-0">
            <div className="card-body p-5">
                <div className="bg-light-secondary border p-13 rounded-3 position-relative">
                    <div className="d-flex flex-stack flex-grow-1 align-items-center">
                        <div className="fw-bold">
                            <h4 className="text-gray-800 fw-bolder mb-3">
                                {message}
                            </h4>
                            <div className="fs-6 text-gray-600">
                                Please reload the page.
                            </div>
                        </div>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageError
