import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js";
import { useTheme } from "./theme-provider.js"
import PageError from '../common_component/PageError.js';
import { Pagination } from 'antd';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import './date-range.css'


export const PaymentResponse = () => {
	const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);
	const [ResponseModalShow, setResponseModalShow] = useState(false);
	const [startDate, setStartDate] = useState(moment.utc().subtract(6, "days").format("YYYY-MM-DD"))
	const [endDate, setEndDate] = useState(moment.utc().format("YYYY-MM-DD"));

	const [WalletAmount, setWalletAmount] = useState("0.00");
	const [TotalDebit, setTotalDebit] = useState("0.00");
	const [TotalCredit, setTotalCredit] = useState("0.00");
	const [PaymentLogDetails, setPaymentLogDetails] = useState([]);
	const [FilterPaymentLogDetails, setFilterPaymentLogDetails] = useState([]);
	const [loading, setloading] = useState(false);
	const [state, setstate] = useState(2);

	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [paymentWalletLength, setPaymentWalletLength] = useState(0);
	const [paymentWalletApiState, setPaymentWalletApiState] = useState(false);

	const [Amount, setAmount] = useState(1);
	const [razorpayOrderId, setRazorpayOrderId] = useState("");
	const [WalletModalShow, setWalletModalShow] = useState(false);
	const [LiveCurrencyData, setLiveCurrencyData] = useState([]);
	const [Currency_conversion_value, setCurrency_conversion_value] = useState(1);
	const intervalRef = useRef(null);

	var page_name = "payment-wallet";

	const presets = [
		{ label: 'Today', start: new Date(), end: new Date() },
		{ label: 'Last 7 days', start: new Date(new Date().setDate(new Date().getDate() - 7)), end: new Date() },
		{ label: 'Last 30 days', start: new Date(new Date().setDate(new Date().getDate() - 30)), end: new Date() },
		{ label: 'Last 90 days', start: new Date(new Date().setDate(new Date().getDate() - 90)), end: new Date() }
	];

	const { user, organization_id, organization_currency, user_role, organization_name, paymentResponseFlag, setPaymentResponseFlag } = useAuthData();
	const { theme, dropdownStyleDark } = useTheme();
	const apiController = useRef(new AbortController());
	const daterange_picker_ref = useRef();

	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	async function getlivecurrency() {
		var object = {};
		object.email = user.email;

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-live-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		if (body.length > 0) {
			let arr = [];
			arr.push(body[0].data.quotes)
			setLiveCurrencyData(arr);
		}
	}

	async function getPaymentWalletDetails() {

		var object = {};
		object.email = user.email;


		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-payment-wallet-details`, { signal: apiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		if (body.operation == "success" && body.data.length > 0) {
			setWalletAmount(body.data[0].amount);
		}
		else if (body.operation == "success" && body.data.length === 0) {
			setWalletAmount("0.00");
			setTotalCredit("0.00");
			setTotalDebit("0.00");
		}
	}

	async function getPaymentLogDetails(start, end, page_number, Size) {

		var object = {};
		object.email = user.email;
		if (paymentWalletApiState) {
			apiController.current.abort()
			apiController.current = new AbortController()
		}
		setloading(true);
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-payment-wallet-log`, { signal: apiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "current_page": page_number - 1, "start_date": start, "end_date": end, "rows_per_page": Size, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		if (body.operation == "success" && body.data.list.length > 0) {
			let arr_deb = body.data.list.filter(option => option.payment_operation == "debit" && option.payment_status == "success");
			let arr_cred = body.data.list.filter(option => option.payment_operation == "credit" && option.payment_status == "success");
			setTotalCredit(arr_cred.reduce((p, o) => { return p + (Math.round(Number(o.amount) * 100) / 100) }, 0));
			setTotalDebit(arr_deb.reduce((p, o) => { return p + (Math.round(Number(o.amount) * 100) / 100) }, 0));
			setFilterPaymentLogDetails(body.data.list);
			setPaymentWalletLength(body.data.count)
			setloading(false);
		}
	}

	useEffect(() => {
		if (user !== null && user.email && organization_id != "") {
			let p1 = getPaymentWalletDetails()
			let p2 = getPaymentLogDetails(startDate, endDate, currentPage, rowsPerPage)
			//let p3 = getlivecurrency();
			Promise.all([p1, p2])
				.then(() => {
					setstate(1)
					console.log("all apis done")
				})
				.catch((err) => {
					setstate(0)
					console.log(err)
				})
		}
	}, [user, organization_id])

	useEffect(() => {
		if (state == 1) {
			getPaymentLogDetails(startDate, endDate, currentPage, rowsPerPage)
		}
	}, [startDate, endDate, currentPage, rowsPerPage])

	useEffect(() => {

		if (LiveCurrencyData.length > 0 && organization_currency !== null) {

			const conversion_value = Math.round((LiveCurrencyData[0]["USD" + organization_currency.currency_code]) * 100) / 100;
			setCurrency_conversion_value(conversion_value);
		}
	}, [organization_currency, LiveCurrencyData])

	const initializeRazorpay = () => {
		return new Promise((resolve) => {
			const script = document.createElement("script");
			script.src = "https://checkout.razorpay.com/v1/checkout.js";

			script.onload = () => {
				resolve(true);
			};
			script.onerror = () => {
				resolve(false);
			};

			document.body.appendChild(script);
		});
	};

	const purchase_plan = async () => {
		if (check_amount(Amount)) {
			const res = await initializeRazorpay();
			if (!res) {
				alert("Razorpay SDK Failed to load");
				return;
			}
			var object = {};
			object.email = user.email;
			object.amount = Number(Amount);
			object.payment_flag = "regular";
			object.currency = organization_currency;
			object.organization_id = organization_id;
			object.currency_conversion_value = Currency_conversion_value;
			setMainSubmitButtonState(true);
			var headers = {
				headers: {
					"Content-Type": "application/json",
					"Accept": "*/*",
					"token": getCookie("access_token")
				}
			}
			axios.post(`${process.env.REACT_APP_SERVER_URL}/add-payment-wallet-log`, object, headers).then(async (res) => {
				if (res.data && res.data.operation == "success") {
					const orderUrl = `${process.env.REACT_APP_SERVER_URL}/create-session-wallet-recharge`;
					object.type = "Regular_Wallet_Recharge";
					object.id = res.data.id;
					const response = await axios.post(orderUrl, object, headers);
					const { data } = response;
					var options = {
						key: process.env.REACT_APP_RAZOR_PAY_KEY_ID, // Enter the Key ID generated from the Dashboard
						name: "SpotERP",
						currency: data.currency,
						order_id: data.id,
						amount: Number(Amount),
						image: `${process.env.REACT_APP_LOGO}`,
						handler: async (response) => {

							// Validate payment at server - using webhooks is a better idea.
							try {
								let payment_object = {};
								payment_object.amount = Number(Amount);
								setRazorpayOrderId(response.razorpay_order_id);
								payment_object.razorpay_payment_id = response.razorpay_payment_id;
								payment_object.razorpay_signature = response.razorpay_signature;
								payment_object.razorpay_order_id = response.razorpay_order_id;
								const captureUrl = `${process.env.REACT_APP_SERVER_URL}/capture-razorpay-payment`;
								const captureResponse = await axios.post(captureUrl, payment_object, headers)
								if (captureResponse.data) {
									setAmount(0);
									setPaymentResponseFlag("success");
									setResponseModalShow(true);
									setWalletModalShow(false);
									setMainSubmitButtonState(false);
								}
							}
							catch (err) {
								console.log(err);
							}
						},
						// prefill: {
						// 	name: selected_billing_address?.full_name,
						// 	email: selected_billing_address?.email,
						// 	contact: selected_billing_address?.phone,
						// }
					}
					const paymentObject = new (window).Razorpay(options);
					paymentObject.open();
				}
			})
		}
	}

	useEffect(() => {
		if (ResponseModalShow) {
			intervalRef.current = setInterval(async () => {
				getPaymentWalletDetails()
				getPaymentLogDetails(startDate, endDate, currentPage, rowsPerPage)
			}, 2000);
			return () => clearInterval(intervalRef.current);
		}
	}, [ResponseModalShow]);

	useEffect(() => {
		if (razorpayOrderId !== "" && FilterPaymentLogDetails.length > 0) {
			//console.log(FilterPaymentLogDetails);
			if (FilterPaymentLogDetails.find(x => x.payment_response.payload.payment.entity.order_id == razorpayOrderId) !== undefined) {
				clearInterval(intervalRef.current);
				setResponseModalShow(false);
				setRazorpayOrderId("");
			}
		}
	}, [razorpayOrderId, FilterPaymentLogDetails]);

	const check_amount = (Amount) => {

		if (Amount <= 0) {
			swal("oops!", "Amount should be greater than Zero", "warning").then((value) => {
				setAmount(1);
				return false;
			});
		}
		return true;
	}

	return (
		<>
			<div className="d-flex flex-column flex-column-fluid">
				<div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
					<div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
						<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Payment Wallet
							<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
							<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
						</h1>
						<span className="badge badge-success fs-5">{organization_name}</span>
					</div>
				</div>
				<div id="kt_app_content" className="app-content flex-column-fluid">
					<div id="kt_app_content_container" className="app-container container-xxl">

						<br />
						{state == 1 ?
							<>
								<div className="card mb-5 mb-xl-10">
									<div className="card-body py-10">
										<h2 className="mb-9">Payment Wallet</h2>
										<div className="row">
											<div className="col">{console.log(organization_currency)}
												<div className="card card-dashed flex-center min-w-175px my-3 p-6">
													<span className="fs-4 fw-semibold text-info pb-1 px-2">Total Wallet Credit</span>
													<span className="fs-lg-2tx fw-bold d-flex justify-content-center">{(organization_currency !== null && organization_currency.currency_code === "INR") ? <>₹</> : <>$</>}
														<span className="counted" >{TotalCredit}</span></span>
												</div>
											</div>

											<div className="col">
												<div className="card card-dashed flex-center min-w-175px my-3 p-6">
													<span className="fs-4 fw-semibold text-danger pb-1 px-2">Total Wallet Debit</span>
													<span className="fs-lg-2tx fw-bold d-flex justify-content-center">{(organization_currency !== null && organization_currency.currency_code === "INR") ? <>₹</> : <>$</>}
														<span className="counted" >{TotalDebit}</span></span>
												</div>
											</div>
											<div className="col">
												<div className="card card-dashed flex-center min-w-175px my-3 p-6">
													<span className="fs-4 fw-semibold text-success pb-1 px-2">Available Balance</span>
													<span className="fs-lg-2tx fw-bold d-flex justify-content-center">{(organization_currency !== null && organization_currency.currency_code === "INR") ? <>₹</> : <>$</>}
														<span className="counted" >{WalletAmount}</span></span>
												</div>
											</div>
										</div>
										<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6">
											<span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z" fill="currentColor"></path>
													<path opacity="0.3" d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z" fill="currentColor"></path>
												</svg>
											</span>

											<div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
												<div className="mb-3 mb-md-0 fw-semibold">
													<h4 className="text-gray-900 fw-bold">Add Your Money to Wallet</h4>
													<div className="fs-6 text-gray-700 pe-7">Add money securily to your wallet. Commision is $25 per transaction under $50,000</div>
												</div>
												<a onClick={() => { setWalletModalShow(true) }} className="btn btn-primary px-6 align-self-center text-nowrap">Add Money</a>
											</div>
										</div>
									</div>
								</div>
								<div className="card">
									<div className="card-header card-header-stretch">
										<div className="card-title">
											<h3>Transaction History</h3>
										</div>
										<div className="card-toolbar">
											<DateRangePickerComponent
												ref={daterange_picker_ref}
												allowEdit={false}
												focus={(e) => {
													daterange_picker_ref.current.show()
												}}
												delayUpdate={true}
												presets={presets}
												change={(e) => {
													setStartDate(e.startDate);
													setEndDate(e.endDate);
												}}
												startDate={startDate}
												endDate={endDate}
											/>
										</div>
									</div>

									<div className="tab-content">
										<div className="card-body p-4 tab-pane fade show active" role="tabpanel" aria-labelledby="#kt_referrals_tab_1">
											<div className="table-responsive">
												{FilterPaymentLogDetails.length > 0 ?
													<table className="table table-row-bordered align-middle gy-4 gs-9">
														<thead className="border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-light bg-opacity-75">
															<tr>
																{/* <th className="min-w-125px ps-9">Order ID</th> */}
																<th className="min-w-125px">Date</th>
																<th className="min-w-125px">Amount</th>
																<th className="min-w-125px">Status</th>
																<th className="min-w-125px ps-0">Payment Type</th>
															</tr>
														</thead>
														<tbody className="fs-6 fw-semibold text-gray-600">
															{loading ?
																<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
																:
																<>
																	{FilterPaymentLogDetails.map((obj, index) => {
																		return (
																			<>
																				<tr>
																					{/* <td className="ps-9">{obj.unique_id}</td> */}
																					<td>{moment(obj.timestamp).format('lll')}</td>
																					{obj.payment_operation == "credit" ?
																						<td className="text-success">{obj.currency == "usd" ? "$" : "Rs."}{obj.amount}</td> :
																						<td className="text-danger">{obj.currency == "usd" ? "$" : "Rs."}{obj.amount}</td>
																					}
																					{obj.payment_status == "success" ? <td className="text-success">{obj.payment_status}</td> :
																						<td className="text-danger">{obj.payment_status}</td>
																					}
																					{obj.payment_operation == "credit" ?
																						<td className="text-success">Credit</td> :
																						<td className="text-danger">Debit</td>
																					}
																				</tr>
																			</>
																		);
																	}
																	)}
																</>}
														</tbody>
													</table>
													:
													<>
														<br />
														<div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
															<span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
																<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z" fill="currentColor"></path>
																	<path opacity="0.3" d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z" fill="currentColor"></path>
																</svg>
															</span>

															<div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
																<div className="mb-3 mb-md-0 fw-semibold">
																	<h4 className="text-gray-900 fw-bold">You Don't have any transaction.</h4>
																	<div className="fs-6 text-gray-700 pe-7">Add Money To Start Your First Transaction</div>
																</div>
															</div>
														</div>
													</>
												}
											</div>
										</div>
										<div className='card-footer'>
											<div className="mt-5 d-flex justify-content-center">
												<Pagination
													total={paymentWalletLength}
													onChange={(page, rowsPerPage) => {
														setCurrentPage(page);
														setRowsPerPage(rowsPerPage);
													}}
													showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
													pageSize={rowsPerPage}
													current={currentPage}
													showSizeChanger={true}
													showQuickJumper={true}
												/>
											</div>
										</div>
									</div>
								</div>
							</>
							:
							<>
								<div className="card  pt-0 pb-5 px-3 px-xl-5">
									{(state == 2) ?

										<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
										:
										<>
											<PageError />
										</>
									}
								</div>
							</>
						}
						<br />
					</div>
				</div>
			</div>

			<Modal show={ResponseModalShow} size="md" centered>
				<Modal.Header>
					<Modal.Title></Modal.Title>
					<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setResponseModalShow(false); setPaymentResponseFlag("") }}>
						<span className="svg-icon svg-icon-1">
							<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
								<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
									<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
									<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
								</g>
							</svg>
						</span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className='container'>
						<div className=" me-n7 pe-7">
							<div className="pb-12 text-center">
								<h1 className="fw-bold text-dark">{paymentResponseFlag == "success" ? <>Payment Wallet Successfuly Recharged!</> : <>Payment Failure</>}</h1>
								<div className="fw-semibold text-muted fs-4">You will receive an email with with the summary of your latest top up!</div>
								{paymentResponseFlag == "success" && <img src="/assets/media/illustrations/sketchy-1/2.png" alt="" className="mw-100 h-200px h-sm-325px" />}
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" onClick={(e) => { setPaymentResponseFlag(""); setResponseModalShow(false); }} className="btn btn-secondary">Close</button>
				</Modal.Footer>
			</Modal>

			<Modal show={WalletModalShow} onHide={() => { setWalletModalShow() }} size="md" centered>
				<Modal.Header>
					<Modal.Title>Add Money To Payment Wallet</Modal.Title>
					<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setWalletModalShow(false) }}>
						<span className="svg-icon svg-icon-1">
							<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
								<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
									<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
									<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
								</g>
							</svg>
						</span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className='container'>
						<div className=" me-n7 pe-7" >
							<div className="fv-row mb-10 fv-plugins-icon-container fv-plugins-bootstrap5-row-invalid">
								<div className="row">
									<div className="col-md-6">
										<label className="d-flex align-items-center fs-5 fw-semibold mb-2">
											<span className="required">Amount ({(organization_currency !== null && organization_currency.currency_code === "INR") ? <>₹</> : <>$</>})</span>
											<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" aria-label="Specify your unique app name" data-kt-initialized="1"></i>
										</label>
										<input type="number" className="form-control form-control-lg form-control-solid" name="name" placeholder="" value={Amount} onChange={(e) => {
											setAmount(e.target.value);
											//check_amount(e.target.value);
										}} />

										{/*<div className="fv-plugins-message-container invalid-feedback"><div data-field="name" data-validator="notEmpty">App name is required</div></div>*/}
									</div>
								</div>
							</div>

						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" onClick={(e) => { setWalletModalShow(false); }} className="btn btn-secondary">Close</button>
					<button
						onClick={purchase_plan} className="btn btn-primary" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState}>
						<span className="indicator-label">Proceed</span>
						<span className="indicator-progress">Please wait...
							<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
						</span>
					</button>
				</Modal.Footer>
			</Modal>
		</>);

};

export default PaymentResponse;
