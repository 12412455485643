import React from 'react';
import ReactDOM from 'react-dom';
import { Link,Redirect } from 'react-router-dom'
function Footer() {
	
	return (
		<>
			<div className='bg-body container-fluid py-3 border border-top border-primary d-flex align-items-center' style={{ position: 'sticky', bottom: 0, width: '-webkit-fill-available', maxHeight: "10%", height: "10%" }}>
				<div className="app-container container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3">
					<div className="text-dark order-2 order-md-1">
						<span className="text-muted fw-semibold me-1">2022©</span>
						<a href="/" className="text-gray-800 text-hover-primary">ADOERP</a>
					</div>
					<ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
						<li className="menu-item">
							<a href="/" className="menu-link px-2">About</a>
						</li>
						<li className="menu-item">
							<a href="/" className="menu-link px-2">Support</a>
						</li>
						<li className="menu-item">
							<a href="/" className="menu-link px-2">Purchase</a>
						</li>
					</ul>
				</div>
			</div>
		</>
	);
}
export default Footer;