import React, { useState, useEffect, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom'
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { getCookie } from '../common_component/cookie.js';
import StatusBadge from '../common_component/StatusBadge.js';
import { useAuthData } from "./auth-provider.js";
import { useTheme } from "./theme-provider.js";
import { Pagination } from 'antd';
import PageError from '../common_component/PageError.js';

export const SubscriptionHistory = () => {

    let [state, setstate] = useState(2);

    const [Module, setModule] = useState("All");
    const [ModuleList, setModuleList] = useState([{ label: "All", value: "All" }]);

    const [listCount, setListCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchValue, setSearchValue] = useState(null)
    const [purchaseList, setPurchaseList] = useState([]);
    const [purchaseListApiState, setPurchaseListApiState] = useState(false)

    let [timezone_change, set_timezone_change] = useState("+00:00");
    const [ResponseModalShow, setResponseModalShow] = useState(false);
    const apiController = useRef(new AbortController())
    const intervalRef = useRef(null);

    var page_name = "subscription-history";

    const { user, organization_id, user_role, organization_name, paymentResponseFlag, setPaymentResponseFlag } = useAuthData();
    const { theme, dropdownStyleDark } = useTheme();

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    async function getPurchaseList(cp, rpp, search_v) {
        try {
            if (purchaseListApiState) {
                apiController.current.abort()
                apiController.current = new AbortController()
            }
            setPurchaseListApiState(true);
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-purchase-datatable`, { signal: apiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "organization_id": organization_id, "current_page": cp - 1, "rows_per_page": rpp, "search_value": search_v, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            if (body.operation === "success") {
                setPurchaseList(body.data.list)
                setListCount(body.data.count)
            }
            setPurchaseListApiState(false)
        }
        catch (err) {
            if (err.name !== 'AbortError') {
                console.log(err)
            }
        }
    }

    async function getaccountdetails() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-organization-timezone-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.length > 0) {
            if (body[0].timezone_value != "" && body[0].timezone_value != null) {
                set_timezone_change(body[0].timezone_value)
            }
        }
    }

    useEffect(() => {
        if (user !== null && user.email) {
            let p1 = getPurchaseList(currentPage, rowsPerPage, searchValue)
            Promise.all([p1])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(0)
                    console.log(err)
                })
        }

    }, [user])

    useEffect(() => {
        if (state == 1) {
            getPurchaseList(currentPage, rowsPerPage, searchValue)
        }
    }, [currentPage, rowsPerPage, searchValue])

    useEffect(() => {
        if (paymentResponseFlag) {
            setResponseModalShow(true);
        }
    }, [])

    useEffect(() => {
        if(ResponseModalShow)
        {
            intervalRef.current = setInterval(async () => {getPurchaseList(currentPage, rowsPerPage, searchValue)}, 3000);
            return () => clearInterval(intervalRef.current);
        }
      }, [ResponseModalShow]);

      useEffect(() => {
		if (ResponseModalShow && purchaseList.length > 0) {
			if (purchaseList.find(x => x.add_status == "Active") !== undefined) {
				clearInterval(intervalRef.current);
				setResponseModalShow(false);
			}
		}
	}, [ResponseModalShow, purchaseList]);

    return (
        <>
            <div>
                <div className="d-flex flex-stack mb-5">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Subscription History</h1>
                </div>
                <div>
                    {
                        state == 1 ?
                            <>
                                <div className="card mb-5 mb-xl-8">
                                    <div className="card-body py-3">
                                        <div className="mb-5"><input type="text" className='form-control fw-bold border border-primary' value={searchValue || ""} onChange={(e) => { setSearchValue(e.target.value) }} placeholder='Search...' /></div>

                                        <div className="d-flex flex-column align-items-center" style={{ minHeight: "50vh" }}>
                                            {
                                                purchaseListApiState ?
                                                    <div className="spinner-border text-primary w-75px h-75px my-auto"></div>
                                                    :
                                                    purchaseList.length > 0 ?
                                                        <>
                                                            <div className="mb-5 d-flex justify-content-center">
                                                                <Pagination
                                                                    total={listCount}
                                                                    onChange={(page, pageSize) => {
                                                                        setCurrentPage(page);
                                                                        setRowsPerPage(pageSize);
                                                                    }}
                                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                    showQuickJumper={true}
                                                                    pageSize={rowsPerPage}
                                                                    current={currentPage}
                                                                    showSizeChanger={true}
                                                                />
                                                            </div>

                                                            <div className='overflow-auto w-100 flex-grow-1'>
                                                                <table className="table table-row-bordered text-nowrap table-row-gray-100 align-middle gs-0 gy-3" >
                                                                    <thead>
                                                                        <tr className="fw-bold text-muted">
                                                                            <th className="min-w-150px">Product Name</th>
                                                                            <th className="min-w-150px">Subscription Name</th>
                                                                            <th className="min-w-150px">Start Date</th>
                                                                            <th className="min-w-150px">End Date</th>
                                                                            <th className="min-w-150px">Status</th>
                                                                            <th className="min-w-50px sticky-column" style={{ backdropFilter: 'blur(10px)' }}>Actions</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            purchaseList.map((obj) => {
                                                                                return (
                                                                                    <tr key={obj.id}>
                                                                                        <td><span className="badge badge-secondary mb-1 fs-6">{obj.product_name}</span></td>
                                                                                        <td><span className="badge badge-primary mb-1 fs-6">{obj.subscription_name}</span></td>
                                                                                        <td><span className="text-dark fw-bold d-block mb-1 fs-6">{moment(obj.starting_date).format('ll')}</span></td>
                                                                                        <td><span className="text-dark fw-bold d-block mb-1 fs-6">{moment(obj.ending_date).format('ll')}</span></td>
                                                                                        <td><StatusBadge status={obj.add_status} entity={""} /></td>
                                                                                        <td className="sticky-column">
                                                                                            <div className="dropdown">
                                                                                                <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                                    <i className="fas fa-bars px-0"></i>
                                                                                                </button>
                                                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                                    <li className="py-1"><Link to={`/view-subscription/${obj.unique_id}`}className={`dropdown-item cursor-pointer`} >View details</Link></li>
                                                                                                </ul>

                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            <div className="mt-5 d-flex justify-content-center">
                                                                <Pagination
                                                                    total={listCount}
                                                                    onChange={(page, pageSize) => {
                                                                        setCurrentPage(page);
                                                                        setRowsPerPage(pageSize);
                                                                    }}
                                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                    pageSize={rowsPerPage}
                                                                    current={currentPage}
                                                                    showSizeChanger={true}
                                                                    showQuickJumper={true}
                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        <div className="text-muted fs-2 my-auto text-center">No data to be shown <br /> <br />
                                                            {/* <button className='btn fs-3 btn-light-primary' onClick={() => { setFlowModalShow(true) }}>
                                                                Need Help <i className="bi bi-question-circle fs-1"></i>
                                                            </button> */}
                                                        </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            state == 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                <PageError />
                    }
                </div>
            </div>
            <Modal show={ResponseModalShow} size="md" centered>
                <Modal.Header>
                    <Modal.Title></Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setResponseModalShow(false); setPaymentResponseFlag("") }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                            <div class="pb-12 text-center">
                                <h1 class="fw-bold text-dark">{paymentResponseFlag == "success" ? <>Subscricption Successfuly Active!</> : <>Payment Failure</>}</h1>
                                <div class="fw-semibold text-muted fs-4">You will receive an email with with the summary of your latest top up!</div>
                                {paymentResponseFlag == "success" && <img src="/assets/media/illustrations/sketchy-1/2.png" alt="" class="mw-100 h-200px h-sm-325px" />}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={(e) => { setResponseModalShow(false); setPaymentResponseFlag(""); }} className="btn btn-secondary">Close</button>
                </Modal.Footer>
            </Modal>
        </>);

};

export default SubscriptionHistory;
